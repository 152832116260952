import styled from "styled-components";
import { breakpoints } from "../../style/size";

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  opacity: 1;
  color: ${(props) => props.theme.blueish2};
  font-family: "SatoshiMedium";
  font-size: 16px;
  font-weight: 600;
  font-style: Semibold;
  letter-spacing: 0px;
  text-align: left;
  line-height: 16px;
  margin-bottom: 40px;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent items from wrapping to new lines */

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 20px;

    div:nth-child(1) {
      display: flex;
      align-items: center;
    }
  }
`;

export const BreadcrumbsLinks = styled.div`
  display: flex;
  align-items: center;
`;

const Links = styled.div`
  a {
    opacity: 0.5;
    color: ${(props) => props.theme.blueish};

    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 600;
    font-style: Semibold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 16px;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }
  }
`;

const NoLinkItem = styled.div`
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  color: rgba(255, 94, 114, 0.5);
  font-family: "SatoshiMedium";
  font-size: 16px;
  font-weight: 600;
  font-style: Semibold;
  letter-spacing: 0px;
  text-align: left;
  line-height: 16px;

  &:last-child {
    color: ${(props) => props.theme.blueish};
  }

  &:hover {
    text-decoration: underline;

    &:last-child {
      text-decoration: none;
    }
  }
`;

const ImageArrow = styled.span`
  margin: 0 8px;
`;

export default function Breadcrumbs({ items }) {
  return (
    <BreadcrumbsWrapper>
      {items &&
        items.map((item, index) => (
          <BreadcrumbsLinks key={index}>
            {item?.link ? (
              <Links>
                {" "}
                <a href={item.link}>{item.title}</a>
              </Links>
            ) : (
              <NoLinkItem>{item.title}</NoLinkItem>
            )}
            {index < items.length - 1 && (
              <ImageArrow>
                {" "}
                <span style={{ color: "#738089" }}>/</span>
              </ImageArrow>
            )}
          </BreadcrumbsLinks>
        ))}
    </BreadcrumbsWrapper>
  );
}
