import styled from "styled-components";
import { breakpoints } from "./size";

export const MatchTableWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

export const LeftTable = styled.div`
  width: 30%;

  @media only screen and (${breakpoints.mobile}) {
    width: 80%;
  }
`;

export const Top = styled.div`
  display: flex;

  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;

    li {
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiMedium";
      font-size: 12px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0.5px;
      text-align: left;
      line-height: 18px;
    }
  }
`;

export const Bottom = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  margin-top: 6px;
`;

export const TeamMatches = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: row;
  align-items: end;
  position: relative;
  background: ${(props) => props.theme.linesOpacity};

  .more {
    position: absolute;
    top: 10px;
    right: 20px;
    transform: rotate(90deg);
    cursor: pointer;
  }

  &.playerStatColumnHeight {
    padding: 0 20px 10px;
    height: 56.5px;
  }

  &:nth-child(even) {
    background: ${(props) => props.theme.row};
  }
`;

export const TeamName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 11px;

  &.matchPlayerStat {
    justify-content: space-between;
    width: 100%;
  }
`;

export const Score = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  position: relative;

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media only screen and (${breakpoints.mobile}) {
      max-width: 150px;
    }
  }

  h3 {
    line-height: 0;
    margin-bottom: 8px;
    time {
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiMedium";
      font-size: 12px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
    }
  }
`;

export const TeamScore = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-right: -8px;

  span {
    position: relative;
    opacity: 1;
    // color: rgba(232, 208, 45, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: right;
    line-height: 24px;
  }

  .more {
    position: absolute;
    top: -20px;
    right: 5px;
    transform: rotate(90deg);
    cursor: pointer;
  }
`;

export const RightTable = styled.div`
  position: relative;
  width: 70%;
  .buttonFilterContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    position: absolute;
    right: 0;
    top: -50px;
  }

  .buttonFilter.absolute {
    position: absolute;
    right: 0;
    top: -50px;
  }

  .buttonFilter {
    border-radius: 1px;
    border: none;
    opacity: 1;
    background-color: ${(props) => props.theme.green};
    color: ${(props) => props.theme.black};
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    min-width: 92px;
    width: fit-content;
    height: 40px;
    cursor: pointer;
    padding: 0 5px;
  }

  table {
  }
`;

export const TableButtons = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  top: -50px;
  right: 0;

  @media only screen and (${breakpoints.mobile}) {
    position: relative;
    top: 0;

    .buttonFilterContainer {
      display: flex;
      column-gap: 10px;
      flex-wrap: wrap;
    }

    .buttonFilter {
      width: fit-content;
      padding: 0 10px;
    }
  }
`;

export const ScrollableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Automatically adjust column widths based on content */

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  th {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;
    // white-space: nowrap;
    padding: 0 7px 7px;

    max-width: 50px;
    width: 50px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr {
    text-wrap: nowrap;

    &:nth-child(even) {
      background: ${(props) => props.theme.row};
    }
  }

  td span {
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
  }

  td {
    // height: 66.5px;
    height: 30.5px;

    text-align: left;
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    // white-space: nowrap;
    vertical-align: bottom;
    // padding-left: 10px;

    padding-left: 14px;
    padding-top: 35px;

    max-width: 50px;
    width: 50px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative; /* Ensure the container is positioned relative */
    bottom: 0;
    text-align: center;

    &.tournamentTable {
      height: 60.5px;
      padding-top: 35px;
    }

    &.playerStatTable {
      height: 60px;
    }

    &.tableNewStyle {
      padding-top: 20px;
      height: 40px;
    }
  }
`;

export const MatchInfo = styled.div`
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  border: 1px solid ${(props) => props.theme.black};
  position: absolute;
  top: -5px;
  right: 0px;
  z-index: 1;

  &.competitionMathcInfo {
    top: 25%;
    right: 10px;
  }
`;

export const MatchContent = styled.div`
  padding: 14px 20px;
  width: 224px;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const TopStats = styled.div`
  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    overflow-x: auto; /* Horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping */

    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    li {
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiMedium";
      font-size: 12px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0.5px;
      text-align: left;
      line-height: 18px;
      padding: 0 10px; /* Add padding to improve readability */
    }
  }
`;

export const BottomStats = styled.div`
  overflow-x: auto;
  margin-top: 10px;

  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    white-space: nowrap; /* Prevent wrapping */
    // padding: 20px 0 20px;
    height: 66px;

    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    li {
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiMedium";
      font-size: 12px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0.5px;
      text-align: left;
      line-height: 18px;
      padding: 0 10px; /* Add padding to improve readability */
      flex: 1; /* Equal width to TopStats li */
      opacity: 1;
      color: ${(props) => props.theme.white};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }
`;

export const ButtonFilter = styled.div`
  border-radius: 1px;
  border: none;
  opacity: 1;
  background-color: ${(props) => props.theme.green};
  opacity: 1;
  font-family: "SpaceGrotesk";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 24px;
  text-transform: uppercase;
  width: 92px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
