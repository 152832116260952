import React, { useState } from "react";

// Style
import {
  AuthWrapper,
  FormWrapper,
  Content,
  ImageWrapper,
  Form,
  ConfirmWrapp,
  LinkWrap,
} from "../../style/Auth";

// Images
import logo from "../../images/ic_logo.svg";

// Components
import TextInput from "../../components/inputs/TextInput";
import PasswordInput from "../../components/inputs/PasswordInput";
import DropdownSingle from "../../components/inputs/DropdownSingle";
import Button from "../../components/button/index";
import ButtonLink from "../../components/link/ButtonLink";

// Language
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation();

  const positions = [
    { label: "Scout", value: "scout" },
    { label: "Manager", value: "manager" },
    { label: "Manager 2", value: "manager2" },
    { label: "Manager 3", value: "manager3" },
  ];

  const country = [
    { label: "Serbia", value: "serbia" },
    { label: "United Kingdom", value: "unitedkingdom" },
    { label: "Qatar", value: "qatar" },
    { label: "Hungary", value: "hungary" },
  ];

  const [name, setName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [selectedOption, setSelectedOption] = useState(positions[0].label);
  const [selectedCountry, setSelectedCountry] = useState(country[0].label);

  const handleNameChange = (newValue) => {
    setName(newValue);
  };

  const handleOrganizationNameChange = (newValue) => {
    setOrganizationName(newValue);
  };

  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };

  const handlePasswordChange = (value) => {
    setFormData({ ...formData, password: value });
    if (value.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (value) => {
    setFormData({ ...formData, confirmPassword: value });
    if (value.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else {
      setPasswordError("");
    }
  };

  const handleOptionChange = (optionValue) => {
    setSelectedOption(optionValue);
  };

  const handleCountryChange = (optionValue) => {
    setSelectedCountry(optionValue);
  };

  return (
    <AuthWrapper>
      <FormWrapper>
        <Content>
          <Form>
            <img className="logo" src={logo} alt={logo} />

            <h1>{t("register.title")}</h1>
            <p>{t("register.desc")}</p>
            <TextInput
              label={t("inputs.name")}
              type={"text"}
              value={name}
              placeholder={t("placeholders.name")}
              onChange={handleNameChange}
            />
            <TextInput
              label={t("inputs.orgName")}
              type={"text"}
              value={organizationName}
              placeholder={t("placeholders.orgName")}
              onChange={handleOrganizationNameChange}
            />
            <DropdownSingle
              title={t("inputs.position")}
              options={positions}
              value={selectedOption}
              onChange={handleOptionChange}
            />
            <DropdownSingle
              title={t("inputs.country")}
              options={country}
              value={selectedCountry}
              onChange={handleCountryChange}
            />
            <TextInput
              label={t("inputs.email")}
              type={"email"}
              value={email}
              placeholder={t("placeholders.email")}
              onChange={handleEmailChange}
            />

            <PasswordInput
              label={t("inputs.password")}
              placeholder={t("placeholders.password")}
              value={formData.password || ""}
              onChange={handlePasswordChange}
              bottomText={t("register.passwordRequest")}
              // error={passwordError}
            />
            <PasswordInput
              label={t("inputs.confPassword")}
              placeholder={t("placeholders.confPassword")}
              value={formData.confirmPassword || ""}
              onChange={handleConfirmPasswordChange}
              // error={passwordError}
            />

            <ConfirmWrapp>
              <Button
                title={t("register.button")}
                size={"full"}
                sizeResponse={"full"}
              />
              <span>
                {t("register.agree")}{" "}
                <ButtonLink
                  title={t("register.terms")}
                  href="/terms"
                  color={"normal"}
                  hoverColor={"primary"}
                  underline
                  size="small"
                />{" "}
                {t("register.and")}{" "}
                <ButtonLink
                  title={t("register.privacy")}
                  href="/privacy"
                  color={"normal"}
                  hoverColor={"primary"}
                  underline
                  size="small"
                />
              </span>
            </ConfirmWrapp>
          </Form>
          <LinkWrap>
            <h3>
              {t("register.haveAcc")}{" "}
              <ButtonLink
                title={t("register.login")}
                size="large"
                color={"primary"}
                underline
                href="/login"
              />{" "}
              {t("register.now")}
            </h3>
          </LinkWrap>
        </Content>
      </FormWrapper>
      <ImageWrapper></ImageWrapper>
    </AuthWrapper>
  );
};

export default Register;
