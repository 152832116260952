import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import backgroundImage from "../../../images/ic_filed.png";
import { breakpoints } from "../../../style/size";
import {
  EventMatchFilter,
  EventsMatchesWrap,
  FootballField,
  ListOfMatches,
} from "../../../style/EventsMatchesStyle";
import {
  EventCard,
  EventCardWrapper,
  EventContent,
  FootballFielOptions,
  OffsetFields,
  ParametersTabWrapper,
  VideoContent,
  VideoSettings,
  VideoSettingsContent,
  VideoSettingsWrapper,
  ZoneOpenContent,
  ZonesWrapper,
} from "../team/EventsMatches";
import {
  DropdownWrapper,
  OpenedDropdown,
} from "../../../components/table/MatchTable";
import TextInput from "../../../components/inputs/TextInput";

import arrowIconUp from "../../../images/up.svg";
import arrowIconDown from "../../../images/down.svg";
import {
  TabContent,
  TabListWrapper,
  TabsWrapper,
  TabTitle,
  TopList,
} from "../../../style/Favorites";
import Button from "../../../components/button";
import { $apiV1 } from "../../../config/api";
import { TimelineChange } from "../../../components/Events/TimelineChange";
import debounce from "lodash.debounce";
import {
  findMaxSecondMarker,
  getUniqueArrayObjectsByKey,
} from "../../../helpers";
import { getCustomZones, getZones } from "./helpers";

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DropdownButton = styled.div`
    background: transparent;
  border: none;
  border-bottom: 1px solid
    ${(props) =>
    props.error
      ? (props) => props.theme.red
      : (props) => props.theme.blueish2};
  outline: none;
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiRegular";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  padding-left: 5px;
  padding-bottom: 4px;

  &:placeholder {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiRegular";
      font-size: 16px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }

    &:disabled {
      color: ${(props) => props.theme.blueish2}; !important;
    }

    img{
      position: absolute;
      right: 0;
      bottom: 5px;
      cursor: pointer;
    }
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  z-index: 999;
  width: 100%;

  border-radius: 2px;
  opacity: 1;
  background: ${(props) => props.theme.lines};
  max-height: 200px;
  overflow: scroll;

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: #00000012;
    }
  }
`;

const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? "#0000007d" : (props) => props.theme.lines};
  color: ${(props) => (props.selected ? "#fff" : "#000")};

  &:hover {
    background-color: #ddd;
  }
`;

const InfoBox = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.blueish};
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  display: ${(props) => (props.visible ? "block" : "none")};
  top: ${(props) => props.top}px;
  // left: ${(props) => props.left}px;
  // transform: translate(-50%, -100%);
  z-index: 999;
  text-align: center;

  img {
    width: 65px;
    height: 50px;
    object-fit: contain;
    opacity: 1;
  }

  h4 {
    color: ${(props) => props.theme.black};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  h5 {
    color: ${(props) => props.theme.black};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    text-decoration: underline;
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    line-height: 24px;
  }
`;

const ZonesList = styled.div`
  margin-top: 20px;
`;

const ZoneOption = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

const DrawingButton = styled.button`
  margin: 20px;
  height: 40px;
`;

const EventsPlayer = ({ players, paramsList, user }) => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [choosenSeasson, setChoosenSeasson] = useState("");
  const [competitions, setCompetitions] = useState([]);
  const [choosenCompetition, setChoosenCompetition] = useState("");
  const [matchesList, setMatchesList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [response, setResponse] = useState(null);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [hoveredEvent, setHoveredEvent] = useState(null);

  const [selectedZones, setSelectedZones] = useState([]);
  const [zoneRectangles, setZoneRectangles] = useState({});
  const [showDirection, setShowDirection] = useState(false);

  const getAllZones = useCallback((count) => {
    const currentStep = count > 14 ? count / 3 : count;
    if ([12, 14].includes(count)) {
      return getCustomZones({
        width: fieldWidth,
        height: fieldHeight,
        quantity: count,
        step: currentStep,
      });
    }
    return getZones({
      width: fieldWidth,
      height: fieldHeight,
      quantity: count,
      step: currentStep,
    });
  }, []);

  // State to manage drawn shapes
  const [drawing, setDrawing] = useState(false);
  const [shapeType, setShapeType] = useState("rectangle");
  const [drawnShapes, setDrawnShapes] = useState([]);
  const [previewShape, setPreviewShape] = useState(null);

  // Coordinates tracking
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const startXRef = useRef(0);
  const startYRef = useRef(0);
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [drawingStarted, setDrawingStarted] = useState(false);

  const [offsetBegin, setOffsetBegin] = useState("");
  const [offsetEnd, setOffsetEnd] = useState("");

  const [open, setOpen] = useState(false);
  const [openCompetition, setOpenCompetition] = useState(false);
  const [videoSettingsOpen, setVideoSettingsOpen] = useState(false);
  const [zoneOpen, setZoneOpen] = useState(false);

  const [displayedMathes, setDisplayedMathes] = useState(
    () => players?.matches_list || []
  );
  const [lastMatches, SetLastMatches] = useState(
    players?.matches_list?.length || 0
  );
  const [selectedMatch, setSelectedMatch] = useState("");

  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedCompetition, setSelectedCompetition] = useState("");

  const pitchRef = useRef(null);
  const startDrag = useRef(false);

  const [timeLineRange, setTimeLineRange] = useState({ min: 0, max: 90 });

  const [openMatchesCount, setOpenMatchesCount] = useState(false);

  const matches = useMemo(
    () => [
      {
        id: 1,
        value: players?.matches_list?.length || 0,
        title: "All matches",
      },
      {
        id: 2,
        value: 3,
        title: "Last 3 matches",
      },
      {
        id: 3,
        value: 5,
        title: "Last 5 matches",
      },
      {
        id: 4,
        value: 10,
        title: "Last 10 matches",
      },
    ],
    [players]
  );

  const handleSeasonChoose = (item) => {
    setChoosenSeasson(item.id);
    setSelectedSeason(item.years);
    setOpen(false);
  };

  const handleCompetitionChoose = (item) => {
    setChoosenCompetition(item.competition_id);
    setSelectedCompetition(item.competition);
    setOpenCompetition(false);
  };

  const uniqueCompetitions = useMemo(() => {
    if (competitions?.length > 0) {
      const uniqueArray = getUniqueArrayObjectsByKey(
        competitions,
        "competition__id"
      );
      return uniqueArray;
    }
    return [];
  }, [competitions]);

  //  Drawing
  const toggleDrawingMode = () => {
    setDrawing((prev) => !prev);
    if (drawing) {
      setPreviewShape(null);
    }
    setDrawnShapes([]);
  };

  function getMousePositionSVG(event, pos) {
    try {
      if (pitchRef?.current) {
        let point = pitchRef?.current?.createSVGPoint();
        if (point) {
          point.x = event.clientX;
          point.y = event.clientY;
          point = point.matrixTransform(
            pitchRef?.current?.getScreenCTM().inverse()
          );
          return point;
        }
        return pos;
      }
      return pos;
    } catch (e) {
      console.log("get mouse position err", e);
      return pos;
    }
  }

  // Event handlers for drawing
  const handleMouseDown = (event) => {
    const isCustomShape = event?.target?.classList?.contains("custom-shape");
    if (isCustomShape) {
      startDrag.current = true;
      event.target.classList.add("draggble");

      const currX = event.target.getAttribute("x");
      const currY = event.target.getAttribute("y");
      startXRef.current = +currX - event.clientX;
      startYRef.current = +currY - event.clientY;
      return;
    }
    if (drawing) {
      const { x, y } = getMousePositionSVG(event, {
        x: event.clientX,
        y: event.clientY,
      });
      setStartX(x);
      setStartY(y);
      startXRef.current = x;
      startYRef.current = y;
      setCurrentX(x);
      setCurrentY(y);
      setDrawingStarted(true);
    }
  };

  const handleMouseMove = (event) => {
    if (startDrag.current) {
      const isExisShapeDraggable = document.querySelector(".draggble");
      if (isExisShapeDraggable) {
        isExisShapeDraggable.setAttribute(
          "x",
          event.clientX + startXRef.current
        );
        isExisShapeDraggable.setAttribute(
          "y",
          event.clientY + startYRef.current
        );
      }
      return;
    }
    if (drawing && drawingStarted) {
      const { x, y } = getMousePositionSVG(event, {
        x: event.clientX,
        y: event.clientY,
      });
      const offsetX = x;
      const offsetY = y;
      setCurrentX(offsetX);
      setCurrentY(offsetY);

      const width = Math.abs(offsetX - startX);
      const height = Math.abs(offsetY - startY);
      const minX = Math.min(offsetX, startX);
      const minY = Math.min(offsetY, startY);

      setPreviewShape({
        type: shapeType,
        x: minX,
        y: minY,
        width: shapeType === "rectangle" ? width : 0,
        height: shapeType === "rectangle" ? height : 0,
      });
    }
  };

  const recalculateCountEvents = (newShape) => {
    const count = displayedEvents?.filter((event) => {
      const pos = convertCoordinatesWithPadding(event.coord_x, event.coord_y);
      return (
        pos.x >= newShape.x &&
        pos.x <= newShape.x + newShape.width &&
        pos.y >= newShape.y &&
        pos.y <= newShape.y + newShape.height
      );
    }).length;
    newShape.eventCount = count;
    return newShape;
  };

  const handleMouseUp = (event) => {
    if (startDrag.current) {
      const isExisShapeDraggable = document.querySelector(".draggble");
      if (isExisShapeDraggable) {
        const x = +isExisShapeDraggable.getAttribute("x");
        const y = +isExisShapeDraggable.getAttribute("y");
        const id = isExisShapeDraggable.getAttribute("id");
        if (id) {
          const newShapes = [...drawnShapes];
          const foundedShapeIdx = newShapes.findIndex(
            (item) => item.id === +id
          );
          if (foundedShapeIdx >= 0) {
            const shape = recalculateCountEvents(newShapes[foundedShapeIdx]);
            newShapes[foundedShapeIdx] = {
              ...shape,
              x,
              y,
            };
            setDrawnShapes(newShapes);
          }
          document
            .querySelectorAll(".draggble")
            ?.forEach((e) => e?.classList?.remove("draggble"));
        }
      }
      startDrag.current = false;
      return;
    }
    if (drawing && drawingStarted) {
      // Check if drawing has started
      const width = Math.abs(currentX - startX);
      const height = Math.abs(currentY - startY);
      const minX = Math.min(startX, currentX);
      const minY = Math.min(startY, currentY);

      // Create a new shape
      const newShape = {
        type: shapeType,
        x: minX,
        y: minY,
        width: shapeType === "rectangle" ? width : 0,
        height: shapeType === "rectangle" ? height : 0,
        id: Date.now(),
      };

      const shape = recalculateCountEvents(newShape);
      setDrawnShapes([...drawnShapes, shape]);
      setDrawingStarted(false);
      setPreviewShape(null);
    }
  };

  const renderDrawnShapes = () => {
    return drawnShapes.map((shape, index) => (
      <React.Fragment key={index}>
        {shape.type === "rectangle" && (
          <>
            <rect
              id={shape.id}
              x={shape.x}
              y={shape.y}
              width={shape.width}
              height={shape.height}
              fill="#ffffff29"
              stroke="red"
              strokeWidth="2"
              cursor={"move"}
              className="custom-shape"
            />
          </>
        )}
      </React.Fragment>
    ));
  };

  const isMarkerInRectangle = (marker, rectangle) => {
    const { x, y, width, height } = rectangle;
    const pos = convertCoordinatesWithPadding(marker.coord_x, marker.coord_y);
    return (
      pos.x >= x && pos.x <= x + width && pos.y >= y && pos.y <= y + height
    );
  };

  const [drawMarkers, setDrawMarkers] = useState();
  const logMarkersInDrawnRectangles = () => {
    const markersInRectangles = displayedEvents
      ?.filter((event) =>
        drawnShapes.some(
          (shape) =>
            shape.type === "rectangle" && isMarkerInRectangle(event, shape)
        )
      )
      .map((event) => event.id);

    setDrawMarkers(markersInRectangles);
  };

  useEffect(() => {
    logMarkersInDrawnRectangles();
  }, [drawnShapes, response]);

  const renderPreviewShape = () => {
    if (previewShape && previewShape.type === "rectangle") {
      return (
        <rect
          x={previewShape.x}
          y={previewShape.y}
          width={previewShape.width}
          height={previewShape.height}
          fill="transparent"
          stroke="blue"
          strokeWidth="2"
          strokeDasharray="4"
        />
      );
    }
    return null;
  };

  // Zones
  const handleZoneSelection = (zoneNumber) => {
    // If the selected zone is already chosen, uncheck it and clear rectangles
    if (selectedZones.includes(zoneNumber)) {
      setSelectedZones([]);
      setZoneRectangles((prevRectangles) => ({
        ...prevRectangles,
        [zoneNumber]: prevRectangles?.[zoneNumber]?.map((rect) => ({
          ...rect,
          visible: false,
        })),
      }));
      return;
    }

    setSelectedZones([zoneNumber]);

    setZoneRectangles((prevRectangles) => ({
      ...prevRectangles,
      [zoneNumber]: getAllZones(zoneNumber),
    }));
  };

  const countEventsInRectangles = (rectangles, events) => {
    return rectangles.map((rect) => {
      const count = events?.filter((event) => {
        const pos = convertCoordinatesWithPadding(event.coord_x, event.coord_y);
        return (
          pos.x >= rect.x &&
          pos.x <= rect.x + rect.width &&
          pos.y >= rect.y &&
          pos.y <= rect.y + rect.height
        );
      }).length;
      return { ...rect, eventCount: count };
    });
  };

  const [allMarkerIds, setAllMarkerIds] = useState(new Set());
  const [zoneMarkers, setZoneMarkers] = useState();

  const handleRemoveRectangle = (zoneNumber, index) => {
    const updatedRectangles = { ...zoneRectangles };
    const rectangle = updatedRectangles[zoneNumber][index];
    rectangle.visible = !rectangle.visible;

    if (!rectangle.visible) {
      const markersInRectangle = displayedEvents?.filter((event) => {
        const pos = convertCoordinatesWithPadding(event.coord_x, event.coord_y);
        return (
          pos.x >= rectangle.x &&
          pos.x <= rectangle.x + rectangle.width &&
          pos.y >= rectangle.y &&
          pos.y <= rectangle.y + rectangle.height
        );
      });

      setAllMarkerIds((prevIds) => {
        const updatedIds = new Set(prevIds);

        // Add new marker IDs
        markersInRectangle?.forEach((marker) => {
          updatedIds.add(marker.id);
        });

        // Convert Set to array if necessary
        const uniqueMarkerIdsArray = Array.from(updatedIds);
        setZoneMarkers(uniqueMarkerIdsArray);

        return updatedIds;
      });
    }

    setZoneRectangles(updatedRectangles);
  };

  const renderZones = () => {
    return selectedZones.map((zoneNumber) =>
      countEventsInRectangles(
        zoneRectangles[zoneNumber] || [],
        displayedEvents || []
      ).map((rectangle, index) => (
        <g
          key={`zone-${zoneNumber}-${index}`}
          className={`zone-${rectangle?.id || index}`}
          onClick={() => handleRemoveRectangle(zoneNumber, index)}
        >
          <rect
            style={{
              opacity: rectangle.visible ? 1 : 0.5,
            }}
            x={rectangle.x}
            y={rectangle.y}
            width={rectangle.width}
            height={rectangle.height}
            fill="rgba(0, 123, 255, 0.2)"
            stroke="#fff"
            strokeWidth={2}
          />
          <text
            x={rectangle.x + rectangle.width / 2}
            y={rectangle.y + rectangle.height / 2}
            textAnchor="middle"
            alignmentBaseline="central"
            fill="#000"
            fontSize="12"
          ></text>
          <circle
            cx={rectangle.x + rectangle.width / 2}
            cy={rectangle.y + rectangle.height / 2}
            r="30"
            style={{
              strokeWidth: 1,
              stroke: "white",
              fill: "rgba(104, 134, 189, 0.68)",
            }}
          />
        </g>
      ))
    );
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  const handleSelectionChange = (ids) => {
    setMatchesList((prev) =>
      Array.isArray(ids)
        ? [...new Set([...prev, ...ids])]
        : prev.includes(ids)
          ? prev.filter((item) => item !== ids)
          : [...prev, ids]
    );
  };

  const handleSeasons = async () => {
    try {
      setChoosenCompetition("");
      setMatchesList([]);
      const response = await $apiV1.get(
        `/statistics/player/${id}?year_season_id=${choosenSeasson}`
      );

      setDisplayedMathes(response.data?.matches_list);
      setCompetitions(response.data.matches_list);
      SetLastMatches(response.data?.matches_list?.length || 0);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  useEffect(() => {
    handleSeasons();
  }, [choosenSeasson]);

  useEffect(() => {
    if (choosenCompetition) {
      const filteredMatches = displayedMathes
        ?.filter((match) => match.competition_id === +choosenCompetition)
        ?.slice(0, lastMatches)
        ?.map((match) => match.id);
      setMatchesList(filteredMatches);
    } else {
      const filteredMatches = displayedMathes
        ?.slice(0, lastMatches)
        ?.map((match) => match.id);
      setMatchesList(filteredMatches);
    }
  }, [choosenCompetition, lastMatches, displayedMathes]);

  const handleCheckboxChange = (id) => {
    setCheckedIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((item) => item !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  useEffect(() => {
    if (matchesList.length > 0 && checkedIds.length > 0) {
      const fetchData = async () => {
        try {
          const response = await $apiV1.get(`/stats/player/${id}`, {
            params: {
              matches: matchesList.join(","),
              params: checkedIds.join(","),
            },
          });

          const allMarkers = response.data.stats.flatMap((stat) =>
            stat.parameters.flatMap((param) => param.markers)
          );

          setMarkers(allMarkers);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [matchesList, checkedIds]);

  useEffect(() => {
    if (markers.length > 0) {
      const fetchData = async () => {
        try {
          const result = await $apiV1.post("/stats/events_search/", {
            markers: markers,
          });

          setResponse(result.data);
          setDisplayedEvents(result.data.events);
          const max = findMaxSecondMarker(result.data.events);
          setTimeLineRange({ min: 0, max: Math.ceil(+max / 60 / 5) * 5 });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [markers]);

  const fieldWidth = 649;
  const fieldHeight = 492;

  const convertCoordinates = (x, y) => {
    const scaleX = fieldWidth / 100;
    const scaleY = fieldHeight / 100;
    return {
      x: x * scaleX,
      y: fieldHeight - y * scaleY,
    };
  };
  const fieldPadding = 5;

  const convertCoordinatesWithPadding = (x, y) => {
    const scaleX = (fieldWidth - 2 * fieldPadding) / 104;
    const scaleY = (fieldHeight - 2 * fieldPadding) / 70;
    return {
      x: fieldPadding + x * scaleX,
      y: fieldHeight - fieldPadding - y * scaleY,
    };
  };

  const getTotalEventsInUnselectedRectangles = () => {
    if (selectedZones.length === 0 || displayedEvents?.length === 0) {
      return 0;
    }

    const rectanglesInSelectedZones = selectedZones.flatMap(
      (zoneNumber) => zoneRectangles[zoneNumber] || []
    );

    const rectanglesWithCounts = countEventsInRectangles(
      rectanglesInSelectedZones,
      displayedEvents || []
    );

    return rectanglesWithCounts
      .filter((rect) => !rect.visible)
      .reduce((total, rect) => total + rect.eventCount, 0);
  };

  const eventsCount =
    selectedZones.length > 0
      ? getTotalEventsInUnselectedRectangles()
      : displayedEvents?.length || 0;

  const isDotInsideRectangle = (dot, rectangle) => {
    const pos = convertCoordinatesWithPadding(dot.coord_x, dot.coord_y);
    return (
      pos.x >= rectangle.x &&
      pos.x <= rectangle.x + rectangle.width &&
      pos.y >= rectangle.y &&
      pos.y <= rectangle.y + rectangle.height
    );
  };

  const isDotInsideAnyRectangle = (dot, rectangles) => {
    return rectangles.some((rectangle) => isDotInsideRectangle(dot, rectangle));
  };

  const handleOffset = async () => {
    try {
      const response = await $apiV1.patch(`/users/profile_settings/${user}/`, {
        params: {
          offset_range_begin: offsetBegin,
          offset_range_end: offsetEnd,
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMatches = (item) => {
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
  };

  const handleChangeTimelineRange = ({ min, max }) => {
    const newDisplayedEvents = response?.events?.filter(
      (item) => +item.second >= min * 60 && +item.second <= max * 60
    );
    setDisplayedEvents(newDisplayedEvents);
  };

  const debouncedChangeTimeLineRange = debounce(handleChangeTimelineRange, 500);

  const resetAllSettings = () => {
    setDisplayedEvents([]);
    SetLastMatches(0);
    setSelectedMatch('');
    setSelectedSeason("");
    setOffsetBegin("");
    setOffsetEnd("");
    setShowDirection(false);
    setSelectedZones([]);
    setZoneRectangles([]);
    setCheckedIds([]);
    setZoneMarkers([]);
    setDisplayedEvents([]);
    setMarkers([]);
    setResponse(null)
  }


  return (
    <>
      <EventsMatchesWrap>
        <ListOfMatches>
          <EventCardWrapper>
            <h3>Choose Season & Match Parameters</h3>
            <EventCard>
              <EventContent>
                <DropdownWrapper>
                  <div style={{ position: "relative" }}>
                    <TextInput
                      type="text"
                      value={
                        selectedSeason.length == 0
                          ? "Select Season"
                          : selectedSeason
                      }
                      onClick={() => setOpen(!open)}
                      readOnly
                    />

                    <img
                      src={open ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      onClick={() => setOpen(!open)}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {open && (
                    <OpenedDropdown>
                      {players.available_seasons?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => handleSeasonChoose(item)}
                        >
                          {item.years}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>

                <DropdownWrapper>
                  <div style={{ position: "relative" }}>
                    <TextInput
                      type="text"
                      value={
                        selectedCompetition.length == 0
                          ? "Select Competition"
                          : selectedCompetition
                      }
                      onClick={() => setOpenCompetition(!openCompetition)}
                      readOnly
                    />

                    <img
                      src={open ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      onClick={() => setOpenCompetition(!openCompetition)}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {openCompetition && (
                    <OpenedDropdown>
                      {uniqueCompetitions?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => handleCompetitionChoose(item)}
                        >
                          {item.competition}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>

                <DropdownWrapper>
                  <div style={{ position: "relative" }}>
                    <TextInput
                      type="text"
                      value={
                        selectedMatch.length == 0 ? "PRE-SET" : selectedMatch
                      }
                      onClick={() => setOpenMatchesCount(!openMatchesCount)}
                      readOnly
                    />

                    <img
                      src={openMatchesCount ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      onClick={() => setOpenMatchesCount(!openMatchesCount)}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  {openMatchesCount && (
                    <OpenedDropdown>
                      {matches?.map((item, index) => (
                        <div key={index} onClick={() => handleMatches(item)}>
                          {item.title}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>

                <Dropdown ref={dropdownRef}>
                  <DropdownButton
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    {matchesList.length === 0
                      ? "Select Matches"
                      : `${matchesList.length} selected`}
                    <img src={showDropdown ? arrowIconUp : arrowIconDown} />
                  </DropdownButton>
                  <DropdownContent show={showDropdown}>
                    {displayedMathes.map((item, index) => (
                      <DropdownItem
                        key={index}
                        selected={matchesList.includes(item.id)}
                        onClick={() => handleSelectionChange(item.id)}
                      >
                        {item.home_team} {item.score} {item.away_team}{" "}
                        {item.competition}
                      </DropdownItem>
                    ))}
                  </DropdownContent>
                </Dropdown>
              </EventContent>
            </EventCard>
          </EventCardWrapper>

          <EventCardWrapper>
            <h3>Choose Other Parameters</h3>
            <EventCard style={{ maxHeight: "400px", overflow: "scroll" }}>
              <EventContent>
                <TabsWrapper>
                  <TopList
                    needBackGroundColor={true}
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <TabListWrapper
                      style={{ overflow: "auto", textWrap: "nowrap", scrollbarWidth: 'auto', overflowY: 'hidden' }}
                    >
                      {paramsList?.map((item) => {
                        return <TabTitle key={item.id}>{item.title}</TabTitle>;
                      })}
                    </TabListWrapper>
                  </TopList>
                  {paramsList?.map((item) => {
                    return (
                      <TabContent key={item.id}>
                        <ParametersTabWrapper>
                          {item?.actions?.map((action, index) => (
                            <div className="checkboxField" key={action.id}>
                              <label htmlFor={action.id}>
                                <input
                                  id={action.id}
                                  type="checkbox"
                                  checked={checkedIds.includes(action.id)}
                                  onChange={() =>
                                    handleCheckboxChange(action.id)
                                  }
                                />
                                {action.title}
                              </label>
                            </div>
                          ))}
                        </ParametersTabWrapper>
                      </TabContent>
                    );
                  })}
                </TabsWrapper>
              </EventContent>
            </EventCard>
          </EventCardWrapper>
        </ListOfMatches>

        <FootballField
          onMouseDown={drawing ? handleMouseDown : null}
          onMouseMove={drawing ? handleMouseMove : null}
          onMouseUp={drawing ? handleMouseUp : null}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={resetAllSettings}
              title={"Reset all"}
              size={"normal"}
              style={{ margin: "0 20px 20px", width: '40%' }}
            />

            <Button
              onClick={toggleDrawingMode}
              title={drawing ? "Disable Drawing" : "Enable Drawing"}
              size={"normal"}
              style={{ margin: "0 20px 20px", width: '40%' }}
            />
          </div>

          <FootballFielOptions>
            <VideoSettingsWrapper>
              <VideoSettings
                onClick={() => setVideoSettingsOpen(!videoSettingsOpen)}
              >
                <h3>Video Settings</h3>
                <img src={arrowIconUp} />
              </VideoSettings>
              {videoSettingsOpen && (
                <VideoSettingsContent>
                  <VideoContent>
                    <h3>Offset</h3>
                    <OffsetFields>
                      <input
                        type="number"
                        value={offsetBegin}
                        onChange={(e) => setOffsetBegin(e.target.value)}
                      />
                      <input
                        type="number"
                        value={offsetEnd}
                        onChange={(e) => setOffsetEnd(e.target.value)}
                      />
                    </OffsetFields>
                    <button onClick={() => handleOffset()}>add offset</button>

                    <h3 style={{ marginTop: "20px" }}>
                      Football Pitch Settings
                    </h3>
                    <label>
                      <input
                        type="checkbox"
                        id="specifyColor"
                        checked={showDirection}
                        onChange={() => setShowDirection(!showDirection)}
                      />
                      Show Direction
                    </label>

                    <ZonesWrapper onClick={() => setZoneOpen(!zoneOpen)}>
                      <h3>Zones</h3>
                      <img src={zoneOpen ? arrowIconUp : arrowIconDown} />

                      {zoneOpen && (
                        <ZoneOpenContent>
                          {[3, 6, 12, 14, 18].map((zone) => (
                            <ZoneOption key={zone}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={selectedZones.includes(zone)}
                                  onChange={() => handleZoneSelection(zone)}
                                />
                                Zone {zone}
                              </label>
                            </ZoneOption>
                          ))}
                        </ZoneOpenContent>
                      )}
                    </ZonesWrapper>
                  </VideoContent>
                </VideoSettingsContent>
              )}
            </VideoSettingsWrapper>
            <Button
              title={`Watch ${drawMarkers?.length > 0 ? drawMarkers?.length : eventsCount
                }
            events`}
              onClick={() => {
                if (drawMarkers?.length > 0) {
                  window.open(
                    `/tournaments/match/video?markers=${drawMarkers}`,
                    "_blank"
                  );
                } else {
                  const markersToShow =
                    selectedZones.length > 0
                      ? zoneMarkers
                      : displayedEvents?.map((item) => item.id)?.join(",");
                  window.open(
                    `/tournaments/match/video?markers=${markersToShow}`,
                    "_blank"
                  );
                }
              }}
              onContextMenu={() => {
                if (drawMarkers?.length > 0) {
                  window.open(
                    `/tournaments/match/video?markers=${drawMarkers}`,
                    "_blank"
                  );
                } else {
                  const markersToShow =
                    selectedZones.length > 0
                      ? zoneMarkers
                      : displayedEvents?.map((item) => item.id)?.join(",");
                  window.open(
                    `/tournaments/match/video?markers=${markersToShow}`,
                    "_blank"
                  );
                }
              }}
            />
          </FootballFielOptions>

          <svg
            ref={pitchRef}
            width={fieldWidth + 2 * fieldPadding}
            height={fieldHeight + 2 * fieldPadding}
            viewBox={`0 0 ${fieldWidth + 2 * fieldPadding} ${fieldHeight + 2 * fieldPadding
              }`}
            preserveAspectRatio="xMidYMid meet"
            style={{
              border: "1px solid black",
              cursor: drawing ? "crosshair" : "auto",
            }}
          >
            {/* Background Image */}
            <image
              href={backgroundImage}
              x={fieldPadding}
              y={fieldPadding}
              width={fieldWidth}
              height={fieldHeight}
              preserveAspectRatio="xMidYMid slice"
            />
            {renderZones()}
            {renderDrawnShapes()}
            {renderPreviewShape()}

            {/* Render the field layout here */}
            {displayedEvents?.map((event) => {
              const {
                coord_x,
                coord_y,
                coord_x_destination,
                coord_y_destination,
              } = event;
              const position = convertCoordinatesWithPadding(coord_x, coord_y);
              const destinationPosition =
                coord_x_destination != null && coord_y_destination != null
                  ? convertCoordinatesWithPadding(
                    coord_x_destination,
                    coord_y_destination
                  )
                  : null;
              return (
                <React.Fragment key={event.id}>
                  {showDirection && destinationPosition && (
                    <line
                      x1={position.x}
                      y1={position.y}
                      x2={destinationPosition.x}
                      y2={destinationPosition.y}
                      stroke="#ff0000"
                      strokeWidth="2"
                      strokeDasharray="5,5"
                    />
                  )}
                  <circle
                    cx={position.x}
                    cy={position.y}
                    r="5"
                    fill={
                      isDotInsideAnyRectangle(event, drawnShapes)
                        ? "red"
                        : "#040802"
                    }
                    onMouseEnter={() => setHoveredEvent(event)}
                    onMouseLeave={() => setHoveredEvent(null)}
                    onClick={() =>
                      navigate(`/tournaments/match/video?markers=${event.id}`)
                    }
                  />
                </React.Fragment>
              );
            })}
          </svg>

          {/* Info Box */}
          <InfoBox
            visible={!!hoveredEvent}
            top={
              hoveredEvent
                ? convertCoordinates(hoveredEvent.coord_x, hoveredEvent.coord_y)
                  .y
                : 0
            }
            left={
              hoveredEvent
                ? convertCoordinates(hoveredEvent.coord_x, hoveredEvent.coord_y)
                  .x
                : 0
            }
          >
            {hoveredEvent ? (
              <>
                <img
                  src={hoveredEvent.creator.photo}
                  alt={hoveredEvent.creator.photo}
                />
                <h4>{hoveredEvent.creator.display_name}</h4>
                <p>
                  {hoveredEvent.match.home_team.name}{" "}
                  {hoveredEvent.match.away_team.name},{" "}
                  {hoveredEvent.match.competition.name}
                </p>
                <h5>{hoveredEvent.action}</h5>
                {hoveredEvent.recipient && (
                  <p>To {hoveredEvent.recipient.display_name}</p>
                )}
              </>
            ) : (
              <p>No player info available</p>
            )}
          </InfoBox>

          <TimelineChange
            onChange={debouncedChangeTimeLineRange}
            min={timeLineRange?.min}
            max={timeLineRange?.max}
            styleContainer={{ marginTop: 35 }}
          />
        </FootballField>
      </EventsMatchesWrap>
    </>
  );
};

export default EventsPlayer;
