import styled from "styled-components";
import { breakpoints } from "./size";

// Images
import backgorund from "../images/background.svg";
import left from "../images/btn_previous.svg";
import right from "../images/btn_next.svg";

// Baner
export const WrapperBanner = styled.div`
  background: url(${backgorund});
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 140px;
  text-align: center;

  @media only screen and (${breakpoints.mobile}) {
    padding: 140px 15px 0 15px;
  }

  h1 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;
    line-height: 102px;
    width: 878px;
    margin: auto;
    padding-bottom: 10px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 40px;
      letter-spacing: 0px;
      line-height: 52px;
      width: 100%;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0px;
    text-align: center;
    line-height: 40px;
    width: 878px;
    margin: auto;
    padding-bottom: 60px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 24px;
      letter-spacing: 0px;
      line-height: 34px;
      width: 100%;
      padding-bottom: 30px;
    }
  }

  .banerImage {
    margin-bottom: 100px;
    // width: 80%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
      margin-bottom: 80px;
    }
  }
`;

export const VideoClip = styled.div`
  iframe {
    width: 878px;
    margin-bottom: -100px;
    position: relative;
    z-index: 1;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
      margin-bottom: -40px;
    }
  }
`;

// Clients
export const ClientWrapper = styled.div`
  background: ${(props) => props.theme.blueish};
  padding-top: 180px;
  position: relative;

  @media only screen and (${breakpoints.mobile}) {
    padding: 60px 20px 0;
  }

  h1 {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SpaceGrotesk";
    font-size: 80px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 102px;
    width: 600px;
    margin: auto;
    padding-bottom: 60px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 40px;
      letter-spacing: 0px;
      line-height: 52px;
      width: 100%;
      text-align: left;
      padding-bottom: 30px;
    }
  }

  .quoteImage {
    position: absolute;
    top: -20px;
    right: 40px;

    @media only screen and (${breakpoints.mobile}) {
      top: -10px;
      right: 0;
      width: 120px;
    }
  }
`;

export const SliderWrapper = styled.div`
  padding: 0 40px 150px;

  @media only screen and (max-width: 992px) {
    padding: 0 0 120px 0;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 0 140px 0;
  }

  .slick-prev {
    left: unset;
    right: 140px;
    margin-top: 150px;

    @media only screen and (max-width: 992px) {
      margin-top: 140px;
    }

    @media only screen and (max-width: 600px) {
      left: 0;
      right: unset;
      margin-top: 190px;
    }

    &:before {
      content: url(${left});
    }
  }

  .slick-next {
    margin-top: 150px;
    right: 40px;

    @media only screen and (max-width: 992px) {
      margin-top: 140px;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 190px;
      right: unset;
      left: 90px;
    }

    &:before {
      content: url(${right});
    }
  }
`;

export const SliderCard = styled.div`
  h2 {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;
    margin-top: 10px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 32px;
      line-height: 46px;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SatoshiMedium";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

export const SliderTopInfo = styled.div`
  display: flex;
  align-items: end;
  gap: 10px;
`;

export const SliderTitle = styled.div`
  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiBold";
    font-size: 20px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

// Services
export const ServicesWrapper = styled.div`
  background: ${(props) => props.theme.black};
  padding: 100px 40px 60px;

  @media only screen and (${breakpoints.mobile}) {
    padding: 80px 20px 30px;
    overflow: hidden;
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 80px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 102px;
    margin-bottom: 10px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 40px;
      line-height: 52px;
    }
  }
`;

export const ServiceCtaAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 40px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column !important;
    gap: 30px;
    text-align: left;
    align-items: flex-start !important;
    margin-bottom: 30px;
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 40px;
    width: 620px;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
      font-size: 24px;
      line-height: 34px;
    }
  }
`;

export const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 60px;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 30px;
  }
`;

export const ServiceContent = styled.div`
  padding: 60px 113px;
  display: flex;
  flex-direction: row;
  gap: 234px;
  border-radius: 5px;
  opacity: 1;

  @media only screen and (${breakpoints.mobile}) {
    padding: 40px 20px;
    flex-direction: column;
    gap: 20px;

    img {
      width: 109px;
      height: 68px;
    }
  }

  &:nth-child(odd) {
    background: ${(props) => props.theme.blueish};
  }

  &:nth-child(even) {
    background: ${(props) => props.theme.green};
  }
`;

export const ServiceInfo = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 32px;
      line-height: 44px;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SatoshiMedium";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;
    width: 537px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 26px;
      width: 100%;
    }
  }
`;

export const ServiceLogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (${breakpoints.mobile}) {
    margin: 0 -30px;
  }
`;

// About
export const AboutWrapper = styled.div`
  background: ${(props) => props.theme.blueish};
`;

export const TopWrap = styled.div`
  padding-top: 100px;

  @media only screen and (${breakpoints.mobile}) {
    padding: 80px 20px 0;
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SpaceGrotesk";
    font-size: 80px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 102px;
    margin-bottom: 10px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 40px;
      line-height: 52px;
      text-align: left;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 40px;
    width: 877px;
    margin: auto;
    padding-bottom: 60px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 24px;
      line-height: 34px;
      text-align: left;
      width: 100%;
      padding-bottom: 30px;
    }
  }
`;

export const AboutImages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 31px;
  justify-content: center;
  margin-bottom: -100px;

  @media only screen and (max-width: 1200px) {
    overflow: scroll;
    img {
      width: 260px;
      height: 260px;
    }
  }

  @media only screen and (max-width: 600px) {
    overflow: scroll;

    img {
      width: 260px;
      height: 260px;

      &:nth-child(1) {
        padding-left: 800px;
      }
    }
  }
`;

// Contact
export const ContactWrapper = styled.div`
  background: ${(props) => props.theme.black};
  padding-top: 240px;

  @media only screen and (${breakpoints.mobile}) {
    padding: 180px 20px 0;
  }

  p {
    opacity: 1;
    color: rgba(207, 218, 230, 1);
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 40px;
    width: 877px;
    margin: auto;
    padding-bottom: 100px;

    a {
      font-family: "SatoshiMedium";
      font-size: 28px;
      color: ${(props) => props.theme.green};
    }

    @media only screen and (${breakpoints.mobile}) {
      font-size: 24px;
      line-height: 34px;
      width: 100%;
      padding-bottom: 80px;

      a {
        font-size: 24px;
      }
    }
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  position: relative;
  width: fit-content;
  margin: auto;

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 80px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 102px;
    margin-bottom: 10px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 40px;
      line-height: 52px;
    }
  }

  img {
    position: absolute;
    top: -30px;
    right: -70px;

    @media only screen and (${breakpoints.mobile}) {
      width: 44px;
      right: -50px;
    }
  }
`;

export const ContactFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

// Footer
export const FooterWrapper = styled.div`
  background: ${(props) => props.theme.black};
  border-top: 1px solid ${(props) => props.theme.blueish3};
  padding: 61px 40px 60px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (${breakpoints.mobile}) {
    padding: 40px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterLeftSide = styled.div`
  @media only screen and (${breakpoints.mobile}) {
    text-align: center;
  }

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 40px;
    width: 290px;
    margin: 20px 0;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
  }
`;

export const FooterRightSide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 140px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    margin-top: 40px;
    text-align: center;
    gap: 40px;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 8px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style-type: none;

    li {
      a {
        opacity: 1;
        color: ${(props) => props.theme.white};
        font-family: "SatoshiBold";
        font-size: 16px;
        font-weight: 700;
        font-style: Bold;
        letter-spacing: 0.5px;
        text-align: left;
        line-height: 24px;
        text-decoration: none;
      }
    }
  }
`;

export const Content = styled.div``;

// Copyright
export const CopyrightWrapper = styled.div`
  background: ${(props) => props.theme.black};
  padding: 20px 40px;
  border-top: 1px solid ${(props) => props.theme.blueish3};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    padding: 40px 0;
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const Social = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 20px;
    img {
      width: 44px;
    }
  }
`;

export const DesignedBy = styled.div`
  opacity: 1;
  color: ${(props) => props.theme.blueish2};
  font-family: "SatoshiMedium";
  font-size: 16px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 5px;

  @media only screen and (${breakpoints.mobile}) {
    font-size: 12px;
    margin-top: 6px;

    a {
      font-size: 12px;
    }
  }
`;
