// Style
import styled, { css } from "styled-components";
import { breakpoints } from "../../style/size";
import "../../style/Global";

// Image
import closeIcon from "../../images/ic_close.svg";
import successIcon from "../../images/ic_success.svg";

const paddingOptions = {
  withClose: "84px 0 60px",
  withoutClose: "60px 0",
};

const variantStyles = (theme, variant = "left") =>
  ({
    left: css`
      position: absolute;
      left: 0;
    `,
    center: css`
      position: relative;
    `,
    right: css`
      position: absolute;
      right: 0;
    `,
    bottom: css`
      position: absolute;
      bottom: 0;
    `,
    top: css`
      position: absolute;
      top: 0;
    `,
  }[variant]);

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(4, 8, 2, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(5px);
`;

export const ModalWrapper = styled.div`
  ${({ theme, variant }) => variantStyles(theme, variant)}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(24, 31, 4, 1);
  box-shadow: 0px 3px 15px ${(props) => props.theme.green};
  opacity: 1;
  background-color: ${(props) => props.theme.black};
  width: 650px;
  padding: ${(props) => paddingOptions[props.padding]};

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
  }

  h1 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    font-family: "SpaceGrotesk";
    letter-spacing: 0px;
    text-align: center;
    line-height: 50px;
    width: 100%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
      font-size: 22px;
    }
  }

  p,
  span {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-size: 20px;
    font-weight: 400;
    font-family: "SatoshiRegular";
    letter-spacing: 0px;
    text-align: center;
    line-height: 28px;
    width: 100%;
    margin-top: 4px;
    padding: 0;

    @media only screen and (${breakpoints.mobile}) {
      width: 90%;
      margin: auto;
    }
  }

  .modalInput {
    margin: 20px 0;

    @media only screen and (${breakpoints.mobile}) {
      width: 90%;
    }
  }

  // textarea {
  //   margin-top: 20px;
  // }
`;

export const Content = styled.div`
  width: 422px;
  text-align: center;

  span {
    color: ${(props) => props.theme.green} !important;
  }

  @media only screen and (${breakpoints.mobile}) {
    width: 90%;
  }
`;

export const ModalButtonWrap = styled.div`
  margin-top: 30px;
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;

export const CorrectIcon = styled.div`
  margin-bottom: 10px;

  img {
    width: 60px;
    height: 60px;
  }
`;

export const List = styled.div`
  list-style-type: none;
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-decoration: underline;

  a {
    opacity: 1;
    color: rgba(37, 0, 3, 1);
    font-family: "Averta-Semibold";
    font-size: 20px;
    font-weight: 600;
    font-style: Semibold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 26px;
    text-decoration: underline;
  }
`;

const Index = ({
  padding = "withoutClose",
  variant = "center",
  children,
  isOpen,
  setIsOpen,
  closeIc,
  successIc,
  haveTimmer = false,
  redirectlink,
}) => {
  //   const router = useRouter();

  //   useEffect(() => {
  //     if (haveTimmer && isOpen) {
  //       const timeoutId = setTimeout(() => {
  //         setIsOpen(false);
  //         redirectlink &&
  //           router.push({
  //             pathname: `/${redirectlink}`,
  //           });
  //       }, 2000);

  //       return () => clearTimeout(timeoutId);
  //     }
  //   }, [isOpen, setIsOpen, haveTimmer]);

  const handleCloseModal = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <Overlay>
          <ModalWrapper padding={padding} variant={variant}>
            <Content>
              {closeIc && (
                <CloseModal onClick={handleCloseModal}>
                  <img src={closeIcon} alt={closeIcon} />
                </CloseModal>
              )}
              {successIc && (
                <CorrectIcon>
                  <img src={successIcon} alt={successIcon} />
                </CorrectIcon>
              )}
              {children}
            </Content>
          </ModalWrapper>
        </Overlay>
      )}
    </>
  );
};

export default Index;
