import styled from "styled-components";
import { breakpoints } from "./size";

export const VideoWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  width: 75%;

  @media only screen and (${breakpoints.mobile}) {
    padding: 0;
    width: auto;
  }
`;

export const TopWrapperVideo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 20px;
    align-items: baseline;
    margin-bottom: 60px;
  }
`;

export const TopWrapperVideoContent = styled.div`
  @media only screen and (${breakpoints.mobile}) {
    padding: 0 20px;
  }
`;

export const VideoPlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column-reverse;
    padding-bottom: 100px;
  }
`;
export const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VideoActionsContent = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;

  // padding: 40px 20px;

  .video-actions-title {
    padding: 40px 20px 0px 20px;
  }

  h3, span {
    opacity: 1;
    color: ${props => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 400;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    margin-bottom: 20px;
    max-width: 250px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;

    span {
      font-weight: 600;
    }
  }
`;

export const VideoActionsContentTop = styled.div`
  max-height: 50vh;
  min-height: 50vh;
  overflow: auto;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 20px 20px;
  width: -webkit-fill-available;
  position: relative;
`;

export const VideoTitleInfo = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  height: 300px;
  overflow: scroll;
`;

export const VideoLists = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: -webkit-fill-available;
  padding: 18px 20px;
  border-top: 1px solid ${props => props.theme.lines};

  &:hover {
     background-color: ${props => props.theme.blueish3};
     cursor: pointer;
  }

  &.video-braker, &.active {
    background-color: ${props => props.theme.blueish3};
  }

  &:last-child {
    border-bottom: 1px solid ${props => props.theme.lines};
  }

  .videoList {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    #ic_play-button {
      fill: ${props => props.theme.green};
    }
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    width: 16px;
    height: 16px;
    border: 1px solid ${props => props.theme.green}; /* Green color */
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 0.6em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 21%, 80% 0, 43% 62%);
    transform: scale(0);
    transition: transform 0.15s ease-in-out;
    background-color: ${props => props.theme.green}; /* Green color for the check */
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  img {
    cursor: pointer;
  }

  h3 {
    opacity: 1;
   color: ${props => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    margin: 0;
  }
`;

export const FormWrap = styled.div`
  display: flex;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const VideoControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 0px 2px 2px;
  opacity: 1;
  background-color: rgba(44, 50, 48, 1);
  padding: 15px 10px 10px;

  button {
    background: transparent;
    border: none;
    cursor: pointer;

    &.videoSpeed {
      border-radius: 2px;
      opacity: 1;
      background-color: rgba(29, 33, 27, 1);
      padding: 4px;

      h4 {
        opacity: 1;
        color: ${props => props.theme.white};
        font-family: "SatoshiBold";
        font-size: 16px;
        font-weight: 700;
        font-style: Bold;
        letter-spacing: 0.5px;
        text-align: left;
        line-height: 16px;
      }
    }
  }
`;

export const VideoActions = styled.div`
  border-radius: 5px;
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${props => props.theme.eventsPanel};
  width: 40%;

  @media only screen and (${breakpoints.mobile}) {
    width: 85%;
    margin: auto;
  }
`;

export const VideoLeftControls = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const VideoRightControls = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;


export const DownloadTypeSelection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.eventsPanel};
  z-index: 10;

  img {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`