import React, { useState } from "react";

// Style
import {
  ContactWrapper,
  TitleWrap,
  ContactFormWrap,
} from "../../style/LandingPage";

// Images
import Icon from "../../images/contactArrow.svg";

// Components
import Modal from "../../components/modal/index";
import Button from "../../components/button";
import TextInput from "../../components/inputs/TextInput";
import TextArea from "../../components/inputs/TextArea";

// Language
import { useTranslation, Trans } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [textarea, setTextarea] = useState("");

  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };

  const handleTextChange = (newValue) => {
    setTextarea(newValue);
  };

  const handleSendMessage = () => {
    setOpen(false);
    setSuccessOpen(true);
  };

  const modal = (
    <>
      {/* Send message */}
      <Modal isOpen={open} setIsOpen={setOpen} closeIc>
        <h1>{t("landingPopup.getInTouchTitle")}</h1>
        <p style={{ fontSize: "18px", marginBottom: "40px" }}>
          {t("landingPopup.getInTouchDesc")}
        </p>
        <ContactFormWrap>
          <TextInput
            label={t("inputs.email")}
            type={"email"}
            value={email}
            placeholder={t("placeholders.email")}
            onChange={handleEmailChange}
          />
          <TextArea
            label="Message"
            counter={textarea.length}
            onChange={handleTextChange}
          />
        </ContactFormWrap>
        <Button
          title={t("landingPopup.getInTouchButton")}
          color={"primary"}
          size="full"
          onClick={() => handleSendMessage()}
        />
      </Modal>
      {/* End Send Message  */}

      {/* Success Message Sent */}
      <Modal isOpen={successOpen} setIsOpen={setSuccessOpen} successIc>
        <h1>{t("landingPopup.successMessageTitle")}</h1>
        <p style={{ fontSize: "18px", marginBottom: "40px" }}>
          {t("landingPopup.successMessageDesc")}
        </p>
        <Button
          title={t("landingPopup.successMessageButton")}
          color={"primary"}
          size="full"
          onClick={() => setSuccessOpen(false)}
        />
      </Modal>
      {/* End Success Message Sent */}
    </>
  );
  return (
    <ContactWrapper id="contact">
      <TitleWrap>
        <h2>{t("contact.title")}</h2>
        <img src={Icon} alt={Icon} onClick={() => setOpen(!open)} />
      </TitleWrap>
      <p>
        <Trans
          i18nKey="contact.desc"
          components={[<a href="mailto: www.google.com" />]}
        />
      </p>
      {modal}
    </ContactWrapper>
  );
};

export default Contact;
