import React, { useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components'
import { CloseIcon } from '../Formation';

import close from "../../../images/ic_close.svg";
import { $apiV1 } from '../../../config/api';
import {
    AvatarWithCountryContainer,
    BottomContent,
    TeamLeftInfo,
    TeamStatistics,
} from "../../../pages/tournametns/team/Index";

import emptyState from "../../../images/contactLogo.svg";
import starOn from "../../../images/star_on.svg";
import starOff from "../../../images/star_off.svg";
import Button from '../../button';
import { breakpoints } from '../../../style/size';

const PlayerDetailsContainer = styled.div`
    border: 1px solid ${(props) => props.theme.borderModal};
    border-radius: 3px;
    padding: 30px;
    width: 537px;
    min-height: 350px;
    z-index: 1000;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.lines};
    
    h2 {
        font-size: 36px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        color: ${(props) => props.theme.white};
        font-family: "SpaceGrotesk";
    }
`

const PlayerMainInfoContainer = styled.div`
    display: flex;
    gap: 16px;
`

const Stat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
  gap: 10px;

  div {
    width: 45%;
  }

  @media only screen and (${breakpoints.mobile}) {
    align-items: baseline;

    > div {
      width: 100%;
    }
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow: auto;
  width: 100% !important;
  color: ${(props) => props.theme.white};
  font-family: "SatoshiMedium";
  font-size: 15px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;

  > div {
    width: 100% !important;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 15px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`

const Divider = styled.hr`
    border: 1px solid ${(props) => props.theme.black};
    margin: 10px 0px;
    width: 100% !important;
`


export const PlayerDetailsModal = ({ open, onClose, player, match, actionsPlayer, savedPlayer }) => {
    const [loadingPlayerInfo, setLoadingPlayerInfo] = useState(true);
    const [playerInfo, setPlayerInfo] = useState(null);

    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        const getPlayerInfo = async (id, seasonId) => {
            try {
                setLoadingPlayerInfo(true);
                const response = await $apiV1.get(`/statistics/player/${id}`);
                setPlayerInfo({ ...response?.data, player_id: id });
                setIsFavorite(Boolean(response?.data?.in_favorites))
            } catch (e) {
                console.log('get player info err:', e.message);
            } finally {
                setLoadingPlayerInfo(false)
            }
        }

        if (player && !savedPlayer) {
            getPlayerInfo(player);
        }

        if (savedPlayer) {
            setPlayerInfo(savedPlayer);
            setIsFavorite(Boolean(savedPlayer?.in_favorites))
            setLoadingPlayerInfo(false);
        }

        return () => {
            setPlayerInfo(null);
            setLoadingPlayerInfo(true);
            setIsFavorite(true);
        }
    }, [player, savedPlayer])

    const toggleFavorite = async (id) => {
        try {
            if (isFavorite) {
                await $apiV1.delete(
                    `/users/favorites/players/${id}/`,
                );
            } else {
                await $apiV1.post(
                    `/users/favorites/players/`,
                    { player: id },
                );
            }
            setIsFavorite(prev => !prev);
        } catch (error) {
            console.error("Error toggling favorite status:", error);
        }
    };

    const getActionIcon = (marker) => {
        switch (marker.action.id) {
            case 65:
            case 100:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 22 22"
                    >
                        <defs>
                            <filter
                                id="filter-1"
                                width="250.8%"
                                height="250.8%"
                                x="-66.2%"
                                y="-66.1%"
                                filterUnits="objectBoundingBox"
                            >
                                <feOffset
                                    dx="1"
                                    dy="1"
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                ></feOffset>
                                <feGaussianBlur
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                    stdDeviation="2.5"
                                ></feGaussianBlur>
                                <feColorMatrix
                                    in="shadowBlurOuter1"
                                    result="shadowMatrixOuter1"
                                    values="0 0 0 0 0.0156862745 0 0 0 0 0.031372549 0 0 0 0 0.00784313725 0 0 0 0.2 0"
                                ></feColorMatrix>
                                <feMerge>
                                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                    <feMergeNode in="SourceGraphic"></feMergeNode>
                                </feMerge>
                            </filter>
                        </defs>
                        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                            <g
                                fill="#31CA29"
                                fillRule="nonzero"
                                stroke="#FFF"
                                strokeWidth="0.27"
                                transform="translate(-240 -477)"
                            >
                                <g transform="translate(20 340)">
                                    <g filter="url(#filter-1)" transform="translate(224.345 141.867)">
                                        <path d="M5.393 0A5.394 5.394 0 000 5.394 5.394 5.394 0 105.393 0zM9.09 2.34a7.02 7.02 0 00-.798-.624 3.18 3.18 0 00-.407-.42c.457.279.864.632 1.205 1.044h0zm-1.922.189c.457.568.503 1.294.534 1.775l.001.012c-.279.282-1.283 1.171-1.47 1.336-.593-.349-1.74-.59-1.95-.632-.147-.473-.377-1.285-.43-1.87l-.001-.02c.13-.184.631-.83 1.465-1.205.213.005 1.281.164 1.851.604h0zm-5.243-.177c.038-.068.185-.282.765-.919.369-.252.77-.45 1.192-.591h.148c.425 0 .816.02.972.03l.187.905a3.933 3.933 0 00-1.486 1.238 1.03 1.03 0 00-.385-.058c-.457 0-1.074.15-1.292.207-.131-.334-.107-.728-.1-.812h0zM.611 5.736a4.77 4.77 0 01.81-3.025c.142-.11.242-.178.31-.222-.003.19.016.506.137.786-.331.67-.52 1.207-.562 1.596a.933.933 0 00.015.345c-.262.166-.514.45-.638.602a1.189 1.189 0 00-.072-.082h0zm1.804 3.187l-.39-.117A4.78 4.78 0 011 7.317c-.276-.905-.246-1.235-.235-1.3.061-.079.352-.445.645-.634.148.22.602.837 1.181 1.052-.012.487.138.97.269 1.296.104.258.21.456.265.553-.181.172-.606.548-.71.64zm.905-.68c-.055-.09-.569-.97-.54-1.821.085-.08.353-.329.64-.58.558-.49.754-.61.82-.638.193.038 1.45.303 1.976.66.13.345.397 1.61.438 1.807-.19.222-.776.868-1.397 1.2-.751-.049-1.238-.217-1.515-.35a1.545 1.545 0 01-.422-.278zm2.073 1.947c-.064 0-.129-.002-.193-.004a1.87 1.87 0 00.39-.142c.05.018.1.037.152.058l.16.06a4.84 4.84 0 01-.51.028zm3.281-1.678l-.01.06a1.32 1.32 0 01-.27.562c-.6.482-1.313.815-2.065.964a3.843 3.843 0 01-.519-.172l-.166-.063a1.759 1.759 0 01-.253-.85c.645-.361 1.237-1.02 1.417-1.232.237.034.46.051.665.051.583 0 .897-.14 1.035-.225.088.179.193.48.197.898l-.031.007zm1.319-3.548c.054.108.128.29.192.575a4.775 4.775 0 01-1.023 2.819c-.098.04-.193.071-.27.095a2.226 2.226 0 00-.231-.961c.556-.482.634-1.714.644-1.97.281-.146.537-.394.688-.558h0zm-.328-1.748c.034.066.067.134.098.202.04.337.13 1.111.13 1.359l-.002.018c-.13.148-.382.404-.656.55-.37-.488-1.07-.901-1.345-1.052-.03-.457-.078-1.208-.521-1.812l.835-.597c.156.104.88.608 1.461 1.332h0z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case 206:
            case 86:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        version="1.1"
                        viewBox="0 0 16 20"
                    >
                        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                            <g transform="translate(-42 -651)">
                                <g transform="translate(20 54)">
                                    <g transform="translate(20 56)">
                                        <g transform="translate(2 541)">
                                            <path
                                                fill="#F20A46"
                                                fillRule="nonzero"
                                                d="M0 2C0 .895.897 0 2.004 0H9.22c1.107 0 2.005.895 2.005 2v13.2c0 1.105-.898 2-2.005 2H2.004A2.002 2.002 0 010 15.2V2z"
                                            ></path>
                                            <path
                                                fill="#040802"
                                                fillRule="nonzero"
                                                d="M5.812 15c0 2.761 2.244 5 5.01 5a5.005 5.005 0 005.011-5c0-2.761-2.243-5-5.01-5a5.005 5.005 0 00-5.01 5z"
                                            ></path>
                                            <path
                                                fill="#F20A46"
                                                d="M11.624 13a.8.8 0 00-1.603 0v2a.8.8 0 001.603 0v-2zm-.801 4.8a.8.8 0 10.001-1.602.8.8 0 00-.001 1.602z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case 55:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 16 20"
                    >
                        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                            <g transform="translate(-386 -628)">
                                <g transform="translate(366 186)">
                                    <g transform="translate(20 418)">
                                        <g transform="translate(0 24)">
                                            <path
                                                fill="#F5A028"
                                                fillRule="nonzero"
                                                d="M0 2C0 .895.897 0 2.004 0H9.22c1.107 0 2.005.895 2.005 2v13.2c0 1.105-.898 2-2.005 2H2.004A2.002 2.002 0 010 15.2V2z"
                                            ></path>
                                            <path
                                                fill="#040802"
                                                fillRule="nonzero"
                                                d="M5.812 15c0 2.761 2.244 5 5.01 5a5.005 5.005 0 005.011-5c0-2.761-2.243-5-5.01-5a5.005 5.005 0 00-5.01 5z"
                                            ></path>
                                            <path
                                                fill="#F5A028"
                                                d="M11.624 13a.8.8 0 00-1.603 0v2a.8.8 0 001.603 0v-2zm-.801 4.8a.8.8 0 10.001-1.602.8.8 0 00-.001 1.602z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case 77:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        version="1.1"
                        viewBox="0 0 40 38"
                    >
                        <defs>
                            <filter
                                id="filter-1"
                                width="260%"
                                height="280%"
                                x="-70%"
                                y="-78.8%"
                                filterUnits="objectBoundingBox"
                            >
                                <feOffset
                                    dx="2"
                                    dy="2"
                                    in="SourceAlpha"
                                    result="shadowOffsetOuter1"
                                ></feOffset>
                                <feGaussianBlur
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                    stdDeviation="5"
                                ></feGaussianBlur>
                                <feColorMatrix
                                    in="shadowBlurOuter1"
                                    result="shadowMatrixOuter1"
                                    values="0 0 0 0 0.0156862745 0 0 0 0 0.031372549 0 0 0 0 0.00784313725 0 0 0 0.2 0"
                                ></feColorMatrix>
                                <feMerge>
                                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                    <feMergeNode in="SourceGraphic"></feMergeNode>
                                </feMerge>
                            </filter>
                        </defs>
                        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                            <g fillRule="nonzero" transform="translate(-378 -710)">
                                <g transform="translate(366 186)">
                                    <g transform="translate(20 418)">
                                        <g filter="url(#filter-1)" transform="translate(0 114)">
                                            <path
                                                fill="#31CA29"
                                                d="M19.775 13.48l-3.75-3.174a.618.618 0 00-.665-.088.637.637 0 00-.36.575v1.905h-1.046a2.48 2.48 0 01-2.12-1.194L7.786 4.926a4.952 4.952 0 00-4.24-2.387H0v2.54h3.546c.868 0 1.66.447 2.12 1.193l4.048 6.579a4.956 4.956 0 004.24 2.387H15v1.905a.626.626 0 00.625.635c.144 0 .286-.05.4-.148l3.75-3.174c.144-.12.225-.3.225-.488a.635.635 0 00-.225-.488z"
                                            ></path>
                                            <path
                                                fill="#F20A46"
                                                d="M19.775 3.321L16.025.146A.618.618 0 0015.36.06a.632.632 0 00-.36.574v1.905h-1.046a4.952 4.952 0 00-4.24 2.387l-.226.369L10.96 7.69l.873-1.419a2.48 2.48 0 012.12-1.193H15v1.905a.626.626 0 00.625.635c.144 0 .286-.051.4-.148l3.75-3.175c.144-.12.225-.3.225-.487a.635.635 0 00-.225-.488zM6.539 10.086l-.871 1.418a2.481 2.481 0 01-2.122 1.194H0v2.54h3.546c1.735 0 3.32-.893 4.24-2.387l.226-.369-1.473-2.396z"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            default:
                return null;
        }
    }
    const renderPlayerActions = (markers) => {
        return (
            <>
                {markers?.length > 0 ? (
                    <>
                        {
                            markers?.map(marker => {
                                const icon = getActionIcon(marker);
                                return (
                                    <div key={marker.id}>
                                        {icon}
                                        {marker?.action?.title} | {Math.floor((+marker?.second || 0) / 60)}’
                                    </div>
                                )
                            })
                        }
                    </>

                ) : "/"}
            </>
        )
    }

    if (!open) return null;

    return (
        <PlayerDetailsContainer>
            <CloseIcon src={close} alt={close} onClick={() => onClose(playerInfo)} />
            {loadingPlayerInfo ? (
                <Oval
                    visible={true}
                    height="80"
                    width="80"
                     secondaryColor={
                    localStorage.getItem("theme") === "light"
                      ? "#4E5255"
                      : "#CFDAE6"
                      }
                     color={localStorage.getItem("theme") === "light" ? "#2363F7" : "#4fa94d"}
                    ariaLabel="oval-loading"
                    wrapperStyle={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    wrapperClass=""
                />
            ) : (

                <>
                    <PlayerMainInfoContainer>
                        <AvatarWithCountryContainer>
                            <img
                                src={
                                    playerInfo?.player?.photos.length > 0
                                        ? `https://platform.smrtstats.com:8888/${playerInfo?.player?.photos[0]?.image}`
                                        : emptyState
                                }
                                style={{ width: 90, height: 80, objectFit: 'contain' }}
                                alt={playerInfo?.team?.logo}
                            />
                            {playerInfo?.player?.nationality?.length > 0 && (
                                <img
                                    className="flag"
                                    src={playerInfo?.player?.nationality?.[0]?.flag}
                                    alt={playerInfo?.player?.nationality?.[0]?.name}
                                />
                            )}
                        </AvatarWithCountryContainer>

                        <TeamLeftInfo>
                            {playerInfo?.player?.nationality?.length > 0 && <h5>{playerInfo?.player?.nationality?.[0]?.short_name}</h5>}
                            <h2 style={{ margin: 0 }}>
                                {playerInfo?.player?.display_name}
                                <img
                                    src={isFavorite ? starOn : starOff}
                                    alt="Favorite star"
                                    style={{
                                        width: "18px",
                                        height: "100%",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => toggleFavorite(playerInfo?.player_id)}
                                />
                            </h2>
                            {playerInfo?.player?.position?.slice(0, 1)?.map((item, index) => (
                                <h3 key={index}>{item.name}</h3>
                            ))}
                        </TeamLeftInfo>
                    </PlayerMainInfoContainer>
                    <Divider />
                    <BottomContent>
                        <TeamStatistics style={{ padding: 0 }}>
                            <Stat>
                                <div>
                                    <h5>Current Club</h5>
                                    <h4>{playerInfo?.player?.team?.name ?? "/"}</h4>
                                </div>
                                <div>
                                    <h5>Past Club</h5>
                                    <h4>/</h4>
                                </div>
                                <div>
                                    <h5>Secondary Position</h5>
                                    <h4>/</h4>
                                </div>
                                <div>
                                    <h5>Foot</h5>
                                    <h4>{playerInfo?.player?.leg ?? "/"}</h4>
                                </div>
                                <div>
                                    <h5>Age</h5>
                                    <h4>{playerInfo?.player?.birth_date ?? "/"}</h4>
                                </div>
                                <div>
                                    <h5>Height</h5>
                                    <h4>{playerInfo?.player?.height ?? "/"}</h4>
                                </div>
                                <div>
                                    <h5>Weight</h5>
                                    <h4>{playerInfo?.player?.weight ?? "/"}</h4>
                                </div>
                                <Divider />
                                <div style={{ width: '100%' }}>
                                    <h5>Referee Decisions</h5>
                                    <ActionsContainer>
                                        {renderPlayerActions(actionsPlayer)}
                                    </ActionsContainer>
                                </div>
                            </Stat>
                        </TeamStatistics>
                    </BottomContent>
                    {/* <TeamButtons>
                           
                                <Button
                                    title="Initiate contact"
                                    onClick={() => handleInitiate(players)}
                                />
                            </TeamButtons> */}
                    <Button
                        color={"secondary"}
                        title="See more about player"
                        onClick={() => window.open(`/tournaments/player/${playerInfo?.player_id}`, '_blank')}
                    />
                </>
            )
            }
        </PlayerDetailsContainer >
    )
}
