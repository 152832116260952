import styled from "styled-components";
import { breakpoints } from "./size";

export const TableWrap = styled.ul`
  border: 1px solid ${(props) => props.theme.lines};
  border-radius: 5px;
  // border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  height: calc(100vh - 280px);
  margin-bottom: 30px;
  position: relative;

  li {
    &:nth-child(odd) {
      background: ${(props) => props.theme.black};
    }

    &:nth-child(even) {
      background: ${(props) => props.theme.row};
    }
  }
`;

export const TableContent = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 22px 20px;
  cursor: pointer;

  @media only screen and (${breakpoints.mobile}) {
    padding-left: 10px;
  }
`;

export const TableNames = styled.div`
  display: flex;
  align-items: center;
  padding-left: 50px;
  margin-bottom: 6px;

  @media only screen and (${breakpoints.mobile}) {
    padding-left: 0;
  }
`;

export const AlfabetSort = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: calc(60% - 25px);

  @media only screen and (${breakpoints.mobile}) {
    width: 57%;
  }

  h6 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;
  }
`;

export const Divider = styled.div`
  margin-right: 35px;

  @media only screen and (${breakpoints.mobile}) {
    margin-right: 10px;
  }
`;

export const DateSort = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  h6 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 60%;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const MidContent = styled.div`
  width: 10%;
  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const RightContent = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 20px;

  img {
    cursor: pointer;
  }
`;

export const NoVideo = styled.div`
  padding-left: 50px;
  h6 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiItalic";
    font-size: 16px;
    font-weight: 400;
    font-style: Italic;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const AddButton = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  opacity: 1;
  background-color: ${(props) => props.theme.green};
  width: 66px;
  height: 66px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media only screen and (${breakpoints.mobile}) {
    width: 44px;
    height: 44px;
    right: 10px;
    bottom: 10px;

    img {
      width: 22px;
    }
  }
`;

export const SeeMore = styled.div`
  position: relative;
`;

export const Actions = styled.div`
  position: absolute;
  top: 0;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  padding: 14px 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const ActionWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  h6 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  img {
    cursor: pointer;
  }
`;

export const SocialShare = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 45px;
  margin: 40px;

  @media only screen and (${breakpoints.mobile}) {
    gap: 20px;
  }
`;

export const EmailList = styled.div`
  margin-top: 20px;
`;

export const EmailListWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiBold";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;

  img {
    cursor: pointer;
  }
`;

