import styled from 'styled-components'

export const VideoControlsWrapper = styled.div`
    color: ${props => props.theme.black};
    opacity: 1;
    transition: opacity .15s ease-in-out;
    z-index: 100;
    min-height: 100px;
    background-color: ${props => props.theme.blueish3};
    border-radius: 0 0 2px 2px;
`

export const TimeLineWrapper = styled.div`
    .timeline-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: auto;

        .timeline {
            background-color: ${props => props.theme.green2};
            height: 6px;
            position: relative;
            width: 100%;

            .record-point__timeline {
                background-color: #fff;
                height: 6px;
                position: relative;
                z-index: 99;
            }

            .record-point {
                position: absolute;
                transform: translate(-50%, -100%);

                .record-point__time {
                    color: #F20A46;
                    font-weight: 600;
                    text-align: center;
                    font-family: "SatoshiRegular";
                    font-size: 14px;
                    line-height: 5px;
                }
            }

            .thumb-indicator {
                left: calc(var(--progress-position) * 100% - 2px);
                position: absolute;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                height: 17px;
                top: -7px;
                transition: transform .15s ease-in-out;
                z-index: 102;
            }
        }

        .timeline .thumb-indicator, .timeline:after {
            background: ${props => props.theme.green};
            box-shadow: inset -10px 1px 5px .5px var(--main-midnight-blue);
        }

        .timeline:after {
            right: calc(100% - var(--progress-position) * 100%);
        }

        .timeline:after, .timeline:before {
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            top: 0;
        }

        .timeline:before {
            background-color: ${props => props.theme.green};
            display: block;
            right: calc(100% - var(--preview-position) * 100%);
        }
     
    }
`
export const BottomControlsContainer = styled.div`
    padding: 15px 10px;
`

export const MainControlsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const LeftControls = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`

export const CenterControls = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    .current-time {
        color: ${props => props.theme.white};
        font-family: "SatoshiRegular";
        font-size: 16px;
        font-weight: 400;
    }
`

export const RightControls = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`

export const Divider = styled.div`
    width: 100%;
    background: ${props => props.theme.lines};
    height: 1px;
`

export const AdditionalActions = styled.div`
    padding: 8px 10px 13px 10px;
    display: flex;
    align-items: center;
    gap: 40px;

    div {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;

        color: ${props => props.theme.white};
        font-family: "SatoshiMedium";
        font-size: 16px;
    }
`
export const VolumeTooltip = styled.div`
    position: absolute;
    top: 100%;
    left: -30px;
    display: none;
    background-color: ${props => props.theme.lines};
    border: 1px solid #ddd;
    padding: 10px;

    span {
        color: ${props => props.theme.blueish};
        font-family: "SatoshiBold";
        font-size: 15px;
    }

    #volume-slider {
        width: 100%;
        min-width: 80px;
    }

    #volume-level {
        display: block;
        text-align: center;
    }
`

export const VolumeControl = styled.div`
    position: relative;
    display: inline-block;

    &:hover ${VolumeTooltip},
    &:focus-within ${VolumeTooltip} {
        display: block;
    }
`

export const SpeedWrapper = styled.div`
    background: ${props => props.theme.row};
    border-radius: 2px;
    border: 1px solid ${props => props.theme.blueish2};
    padding: 0 3px;
    min-width: 50px;
    text-align: center;

    span {
        color: ${props => props.theme.white};
        font-family: "SatoshiBold";
        font-size: 15px;
    }
`