import React, { useEffect, useState } from "react";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Style
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
  IntroWrap,
} from "../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";
import {
  Heading,
  LeftImage,
  Name,
  PlayerCard,
  PlayerContent,
  PlayerInfo,
  PlayerName,
  PlayerWrapperCard,
  Title,
  TopInfo,
  Info,
  List,
} from "../../style/Player";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import Breadcrumbs from "../../components/breadcrumbs";
import ButtonLink from "../../components/link/ButtonLink";
import Button from "../../components/button/index";

// Images
import profile1 from "../../images/img_league.svg";
import englishFlag from "../../images/ic_eng_flag.svg";
import star from "../../images/ic_start.svg";

// Language
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";

const Id = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Smrt Search",
      link: "/categories",
    },
    {
      title: "Jerome Joshua Webster",
    },
  ];

   const [navigationOpen, setNavigationOpen] = useState(
     localStorage.getItem("navigationOpen")
   );

   useEffect(() => {
     const handleNavigationOpenChange = () => {
       setNavigationOpen(localStorage.getItem("navigationOpen"));
       console.log(localStorage.getItem("navigationOpen"), "ss");
     };

     window.addEventListener(
       "navigationOpenChange",
       handleNavigationOpenChange
     );

     // Cleanup event listener on component unmount
     return () => {
       window.removeEventListener(
         "navigationOpenChange",
         handleNavigationOpenChange
       );
     };
   }, []);

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <IntroWrap>
          <IntroText
            smallTitle={t("smrtSearch.smallTitle")}
            title="Check Out the Player."
          />
        </IntroWrap>

        <Breadcrumbs items={breadcrumbs} />

        <PlayerWrapperCard>
          <PlayerCard>
            <PlayerContent>
              <TopInfo>
                <Heading>
                  <h2>Basic Info</h2>
                  <ButtonLink
                    title="Compare Player"
                    underline
                    color="primary"
                    size="small"
                  />
                </Heading>
                <PlayerName>
                  <LeftImage>
                    <img className="profile" src={profile1} alt={profile1} />
                    <img className="flag" src={englishFlag} alt={englishFlag} />
                  </LeftImage>
                  <Name>
                    <span>United Kingdom</span>
                    <Title>
                      <h2>Jerome Joshua Webster</h2>{" "}
                      <img src={star} alt={star} />
                    </Title>
                    <h5>Defensive Midfielder</h5>
                  </Name>
                </PlayerName>

                <PlayerInfo>
                  <Info>
                    <span>Current Club</span>
                    <h5>Aston Villa</h5>
                  </Info>
                  <Info>
                    <span>Past Club</span>
                    <h5>Crvena Zvezda</h5>
                  </Info>
                  <Info>
                    <span>Secondary Position</span>
                    <h5>Goal Keeper</h5>
                  </Info>
                  <Info>
                    <span>Foot</span>
                    <h5>Both</h5>
                  </Info>
                  <Info>
                    <span>Age</span>
                    <h5>12.10.1993 | 30y</h5>
                  </Info>
                  <Info>
                    <span>Height</span>
                    <h5>167cm</h5>
                  </Info>
                  <Info>
                    <span>Weight</span>
                    <h5>78kg</h5>
                  </Info>
                </PlayerInfo>
              </TopInfo>
              <Button title="Initiate contact" color="primary" />
            </PlayerContent>
          </PlayerCard>

          <PlayerCard>
            <PlayerContent>
              <TopInfo>
                <Heading>
                  <h2>Statistics Info</h2>
                </Heading>

                <List>
                  <span>Matches Played per Competition</span>
                  <ul>
                    <li>• China FA Cup (2023 Chinese FA cup): 2</li>
                    <li>• China Super League (China, Super League): 15</li>
                  </ul>
                </List>

                <List>
                  <span>Minutes Played per Competition</span>
                  <ul>
                    <li>• China FA Cup (2023 Chinese FA cup): 56</li>
                    <li>• China Super League (China, Super League): 976</li>
                  </ul>
                </List>

                <PlayerInfo>
                  <Info>
                    <span>Goals Scored</span>
                    <h5>12</h5>
                  </Info>
                  <Info>
                    <span>Assists</span>
                    <h5>34</h5>
                  </Info>
                  <Info>
                    <span>Yellow Cards</span>
                    <h5>/</h5>
                  </Info>
                  <Info>
                    <span>Red Cards</span>
                    <h5>2</h5>
                  </Info>
                  <Info>
                    <span>Total Passes</span>
                    <h5>6</h5>
                  </Info>
                  <Info>
                    <span>Inaccurate passes</span>
                    <h5>/</h5>
                  </Info>
                  <Info>
                    <span>Accurate Passes</span>
                    <h5>123</h5>
                  </Info>
                  <Info>
                    <span>Accurate Passes (%)</span>
                    <h5>34%</h5>
                  </Info>
                  <Info>
                    <span>Mistakes</span>
                    <h5>3</h5>
                  </Info>
                </PlayerInfo>
              </TopInfo>
              <Button
                title="see matches in current club"
                color="secondary"
                onClick={() => navigate("/smrt-search/current-club")}
              />
            </PlayerContent>
          </PlayerCard>
        </PlayerWrapperCard>
      </MainWrapperFavorite>
    </MainWrapper>
  );
};

export default Id;
