import React, { useState } from "react";

// Style
import {
  MainWrapper,
  RightContent,
  BottomInfo,
  BoxWrrapper,
  BoxContent,
  TopContent,
  ParagraphStyle,
  FormWrap,
} from "../../style/DasboardMain";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import TextInput from "../../components/inputs/TextInput";
import TextArea from "../../components/inputs/TextArea";
import Button from "../../components/button/index";
import Modal from "../../components/modal/index";
import logo from "../../images/contactLogo.svg";

// Images
import contact from "../../images/contact.png";

// Language
import { useTranslation, Trans } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [textarea, setTextarea] = useState("");
  const [open, setOpen] = useState(false);

  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };

  const handleTextChange = (newValue) => {
    setTextarea(newValue);
  };

  const modal = (
    <>
      {/* Success Message Sent */}
      <Modal isOpen={open} setIsOpen={setOpen} successIc>
        <h1>{t("landingPopup.successMessageTitle")}</h1>
        <p style={{ fontSize: "18px", marginBottom: "40px" }}>
          {t("landingPopup.successMessageDesc")}
        </p>
        <Button
          title={t("landingPopup.successMessageButton")}
          color={"primary"}
          size="full"
          onClick={() => setOpen(false)}
        />
      </Modal>
      {/* End Success Message Sent */}
    </>
  );

  return (
    <MainWrapper>
      <Navigation />
      <RightContent>
        <IntroText
          smallTitle={t("contactDashboard.smallTitle")}
          title={t("contactDashboard.title")}
        />

        <BoxWrrapper>
          <BoxContent>
            <TopContent>
              <img
                style={{ width: "60px", height: "60px" }}
                src={logo}
                alt={logo}
              />
              <h4>{t("contactDashboard.supportTitle")}</h4>
            </TopContent>
            <ParagraphStyle style={{ marginBottom: "20px" }}>
              <Trans
                i18nKey="contactDashboard.contactUsDesc"
                components={[<a href="mailto: sales@smrtstats.com" />]}
              />
            </ParagraphStyle>
            <Button
              title={t("contactDashboard.button")}
              color="primary"
              size="large"
              sizeResponse="full"
              onClick={() => (window.location = "mailto:sales@smrtstats.com")}
            />
          </BoxContent>
        </BoxWrrapper>
      </RightContent>
      {modal}
    </MainWrapper>
  );
};

export default Index;
