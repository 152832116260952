import React, { useEffect, useState } from "react";
import axios from "axios";

// Style
import {
  CheckBoxWrapper,
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
} from "../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import Search from "../../components/inputs/Search";
import FavoritesTable from "../../components/table/FavoritesTable";
import LinkTable from "../../components/table/LinkTable";

// Language
import { useTranslation } from "react-i18next";
import { $apiV1 } from "../../config/api";

const Index = () => {
  const { t, i18n } = useTranslation();

  const [isChecked, setIsChecked] = useState(false);
  const [favList, setFavList] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const playersPerPage = 10; // Number of players per page

  const getFavoriteList = async (page, sortOrder) => {
    try {
      const response = await $apiV1.get(
        `/users/favorites/players/?page=${page}&ordering=${
          sortOrder === "asc" ? "created_at" : "-created_at"
        }`
      );
      setFavList(response?.data?.results || []);
      setTotalPlayers(response?.data?.count);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  useEffect(() => {
    getFavoriteList(page, sortOrder);
  }, [page, sortOrder]);

  const handleSortChange = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalPlayers / playersPerPage)) {
      setPage(newPage);
    }
  };

  const [navigationOpen, setNavigationOpen] = useState(
    localStorage.getItem("navigationOpen")
  );

  useEffect(() => {
    const handleNavigationOpenChange = () => {
      setNavigationOpen(localStorage.getItem("navigationOpen"));
      console.log(localStorage.getItem("navigationOpen"), "ss");
    };

    window.addEventListener("navigationOpenChange", handleNavigationOpenChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );
    };
  }, []);

  
  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText
            smallTitle={t("favorites.smallTitle")}
            title={t("favorites.title")}
          />
        </TopWrapper>

        <FavoritesTable
          table={favList}
          sortOrder={sortOrder}
          onSortChange={handleSortChange}
          page={page}
          totalPages={Math.ceil(totalPlayers / playersPerPage)}
          onPageChange={handlePageChange}
        />
      </MainWrapperFavorite>
    </MainWrapper>
  );
};

export default Index;
