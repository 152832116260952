import React, { useState, useEffect } from "react";
import styled from "styled-components";
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;

  label {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    padding-left: 5px;
  }
`;

const SelectInput = styled.select`
  display: none;
`;

const CustomSelect = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.blueish2};
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiRegular";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  padding-left: 5px;

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

const ArrowIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  z-index: 999;
  background: ${(props) => props.theme.lines};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiRegular";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;

  &:hover {
    background-color: ${(props) => props.theme.row};
  }
`;

const DropdownSingle = ({
  title,
  options = [],
  value,
  onChange,
  valueKey = "value", // Default key for value
  labelKey = "title", // Default key for label
  defaultValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    value?.[labelKey] || defaultValue?.[labelKey] || ""
  );

  useEffect(() => {
    if (value) {
      setSelectedValue(value[labelKey]);
    }
  }, [value, labelKey]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (option) => {
    setSelectedValue(option[labelKey]);
    onChange(option); // Passing the entire option object
    setIsOpen(false);
  };

  return (
    <SelectWrapper>
      <label>{title}</label>
      <SelectInput value={selectedValue} onChange={() => {}}>
        {options &&
          options.map((option, index) => (
            <option key={option[valueKey]} value={option[labelKey]}>
              {option[labelKey]}
            </option>
          ))}
      </SelectInput>
      <CustomSelect onClick={toggleDropdown}>
        <span>{selectedValue}</span>
        <ArrowIcon
          src={isOpen ? arrowIconUp : arrowIconDown}
          alt="Dropdown Arrow"
        />
        {isOpen && (
          <DropdownMenu isOpen={isOpen}>
            {options &&
              options.map((option, index) => (
                <DropdownItem
                  key={option[valueKey]}
                  onClick={() => handleOptionChange(option)}
                >
                  {option[labelKey]}
                </DropdownItem>
              ))}
          </DropdownMenu>
        )}
      </CustomSelect>
    </SelectWrapper>
  );
};

export default DropdownSingle;
