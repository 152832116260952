import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, lightTheme, darkTheme } from "./style/Global";
import { StoreProvider } from "./providers/storeProvider";

import "./i18n";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const storedTheme = localStorage.getItem("theme");
const initialTheme = storedTheme === "light" ? lightTheme : darkTheme;

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      {/* <Suspense fallback="...is loading"> */}
      <ThemeProvider theme={initialTheme}>
        <StoreProvider>
          <GlobalStyles />
          <App />
        </StoreProvider>
      </ThemeProvider>
      {/* </Suspense> */}
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
