import React, { useEffect, useState } from "react";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Style
import {
  MainWrapper,
  MainWrapperFavorite,
  IntroWrap,
} from "../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";
import {
  CompareListWrapper,
  GeneralCard,
  PlayerCard,
  Content,
  Dropdown,
  PlayerTitle,
  Title,
  ImageProfile,
  Progress,
} from "../../style/CompareStyle";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import Breadcrumbs from "../../components/breadcrumbs";

// Images
import profile1 from "../../images/img_league.svg";
import englishFlag from "../../images/ic_eng_flag.svg";
import star from "../../images/ic_start.svg";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Compare = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Tournaments",
      link: "/",
    },
    {
      title: "Aston Villa",
      link: "/",
    },
    {
      title: "Compare Jerome Joshua Webster",
    },
  ];

  const [navigationOpen, setNavigationOpen] = useState(
    localStorage.getItem("navigationOpen")
  );

  useEffect(() => {
    const handleNavigationOpenChange = () => {
      setNavigationOpen(localStorage.getItem("navigationOpen"));
      console.log(localStorage.getItem("navigationOpen"), "ss");
    };

    window.addEventListener("navigationOpenChange", handleNavigationOpenChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "navigationOpenChange",
        handleNavigationOpenChange
      );
    };
  }, []);

  return (
    <>
      <MainWrapper>
        <Navigation />
        <MainWrapperFavorite
          className={
            localStorage.getItem("navigationOpen") === "true"
              ? ""
              : "fullWidthPage"
          }
        >
          <IntroWrap>
            <IntroText
              smallTitle={t("smrtSearch.smallTitle")}
              title="Check Out the Player."
            />
          </IntroWrap>

          <Breadcrumbs items={breadcrumbs} />
          <CompareListWrapper>
            <GeneralCard>
              <Content>
                {" "}
                <Dropdown>dropdown</Dropdown>
              </Content>
            </GeneralCard>

            <PlayerCard>
              <Content>
                <Dropdown>search</Dropdown>
                <PlayerTitle>
                  <ImageProfile>
                    <img className="profle" src={profile1} alt={profile1} />
                    <img className="flag" src={englishFlag} alt={englishFlag} />
                  </ImageProfile>
                  <span>Mostar Velez</span>
                  <Title>
                    <h2>Jerome Joshua Webster</h2>
                    <img src={star} alt={star} />
                  </Title>
                </PlayerTitle>

                <Progress>
                  <progress id="file" value="32" max="100">
                    {" "}
                    32%{" "}
                  </progress>
                </Progress>
              </Content>
            </PlayerCard>
            <PlayerCard>
              <Content>
                <Dropdown>search</Dropdown>
              </Content>
            </PlayerCard>
          </CompareListWrapper>
        </MainWrapperFavorite>
      </MainWrapper>
    </>
  );
};

export default Compare;
