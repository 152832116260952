import React from "react";
import { useNavigate } from "react-router-dom";

// Style
import { MainWrapper, RightContent } from "../../style/DasboardMain";
import { ErrorContent, ErrorWrapper } from "../../style/Error";

// Components
import Navigation from "../../components/Navigation/Navigation";
import Button from "../../components/button/index";

// Images
import error from "../../images/ic_error.svg";

// Language
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <MainWrapper>
      <Navigation />
      <ErrorWrapper>
        <ErrorContent>
          <img src={error} alt={error} />
          <h1>{t("error.title")}</h1>
          <p>{t("error.desc")}</p>
          <Button
            title={t("error.button")}
            size="full"
            sizeResponse="full"
            onClick={() => handleBackToHome()}
          />
        </ErrorContent>
      </ErrorWrapper>
    </MainWrapper>
  );
};

export default Index;
