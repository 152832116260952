import React from "react";
import {
  BottomContent,
  BottomTableStats,
  Draw,
  Goals,
  LastFive,
  Lose,
  Points,
  Rang,
  ShortP,
  Square,
  Team,
  TeamsTableWrap,
  TopTableLabel,
  Wins,
} from "../../style/TournamentsPage";

const TeamsTable = ({ table }) => {
  const teamTableLables = [
    {
      id: 1,
      name: "#",
    },
    {
      id: 2,
      name: "Team",
    },
    {
      id: 3,
      name: "P",
    },
    {
      id: 4,
      name: "W",
    },
    {
      id: 5,
      name: "D",
    },
    {
      id: 6,
      name: "L",
    },
    {
      id: 6,
      name: "Goals",
    },
    {
      id: 7,
      name: "Last 5",
    },
    {
      id: 8,
      name: "PTS",
    },
  ];

  return (
    <TeamsTableWrap>
      <TopTableLabel>
        {teamTableLables.map((item, index) => {
          return <h5 key={index}>{item.name}</h5>;
        })}
      </TopTableLabel>

      <BottomTableStats>
        {table?.map((item, index) => {
          return (
            <BottomContent key={index}>
              <Rang>
                <h5>{index + 1}</h5>
              </Rang>
              <Team>
                <h3>{item?.teamName ?? "/"}</h3>
              </Team>

              <ShortP>
                <h5>{item?.p ?? "/"}</h5>
              </ShortP>

              <Wins>
                <h5>{item?.wins ?? "/"}</h5>
              </Wins>
              <Draw>
                <h5>{item?.draw ?? "/"}</h5>
              </Draw>
              <Lose>
                <h5>{item?.lose ?? "/"}</h5>
              </Lose>
              <Goals>
                <h5>{item?.goals ?? "/"}</h5>
              </Goals>
              <LastFive>
                <Square className="win">W</Square>
                <Square className="lose">L</Square>
                <Square className="win">W</Square>
                <Square className="draw">D</Square>
                <Square></Square>
              </LastFive>
              <Points>
                <h5>29</h5>
              </Points>
            </BottomContent>
          );
        })}
      </BottomTableStats>
    </TeamsTableWrap>
  );
};

export default TeamsTable;
