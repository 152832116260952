import React, { useState } from "react";

// Style
import {
  MainWrapper,
  RightContent,
  PageWrapper,
  BoxContent,
  BoxWrrapper,
  FormWrap,
  FormWrapPopup,
  Info,
} from "../../style/DasboardMain";
import { FiedlWrapper } from "../../style/Auth";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import TextInput from "../../components/inputs/TextInput";
import DropdownSingle from "../../components/inputs/DropdownSingle";
import PasswordInput from "../../components/inputs/PasswordInput";
import ButtonLink from "../../components/link/ButtonLink";
import Modal from "../../components/modal/index";
import Button from "../../components/button";

// Language
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();

  const positions = [
    { label: "Scout", value: "scout" },
    { label: "Manager", value: "manager" },
    { label: "Manager 2", value: "manager2" },
    { label: "Manager 3", value: "manager3" },
  ];

  const country = [
    { label: "Serbia", value: "serbia" },
    { label: "United Kingdom", value: "unitedkingdom" },
    { label: "Qatar", value: "qatar" },
    { label: "Hungary", value: "hungary" },
  ];

  const [name, setName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [selectedOption, setSelectedOption] = useState(positions[0].label);
  const [selectedCountry, setSelectedCountry] = useState(country[0].label);

  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({});
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const handleNameChange = (newValue) => {
    setName(newValue);
  };

  const handleOrganizationNameChange = (newValue) => {
    setOrganizationName(newValue);
  };

  const handleOptionChange = (optionValue) => {
    setSelectedOption(optionValue);
  };

  const handleCountryChange = (optionValue) => {
    setSelectedCountry(optionValue);
  };

  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };

  const handlePasswordChange = (value) => {
    setFormData({ ...formData, password: value });
  };

  const handleUpdateButton = () => {
    setOpen(false);
    setSuccessOpen(true);
  };

  const modal = (
    <>
      {/* Change Password */}
      <Modal isOpen={open} setIsOpen={setOpen} closeIc>
        <h1>{t("myAccountDashboard.modalChangePasswordTitle")}</h1>
        <p>{t("myAccountDashboard.modalChangePasswordDesc")}</p>
        <FormWrapPopup style={{ marginTop: "20px" }}>
          <PasswordInput
            label={t("inputs.password")}
            placeholder={t("myAccountDashboard.currentPassword")}
            value={formData.password || ""}
            onChange={handlePasswordChange}
            // error={passwordError}
          />
          <PasswordInput
            label={t("inputs.password")}
            placeholder={t("myAccountDashboard.password")}
            value={formData.password || ""}
            onChange={handlePasswordChange}
            bottomText={t("myAccountDashboard.bottomText")}
            // error={passwordError}
          />
          <PasswordInput
            label={t("inputs.password")}
            placeholder={t("myAccountDashboard.confirmPassowrd")}
            value={formData.password || ""}
            onChange={handlePasswordChange}
            // error={passwordError}
          />
        </FormWrapPopup>
        <Button
          title={t("myAccountDashboard.modalChangePasswordButton")}
          size={"full"}
          onClick={() => handleUpdateButton()}
        />
      </Modal>
      {/* End Change Password */}

      {/* Success Change Password */}
      <Modal isOpen={successOpen} setIsOpen={setSuccessOpen} successIc>
        <h1>{t("myAccountDashboard.successUpdatePasswordTitle")}</h1>
        <p style={{ fontSize: "18px", marginBottom: "40px" }}>
          {t("myAccountDashboard.successUpdatePasswordDesc")}
        </p>

        <Button
          title={t("myAccountDashboard.successUpdatePasswordButton")}
          color={"primary"}
          size="full"
          onClick={() => setSuccessOpen(false)}
        />
      </Modal>
      {/* End Success Change Password */}
    </>
  );

  return (
    <MainWrapper>
      <Navigation />
      <RightContent>
        <IntroText
          smallTitle={t("myAccountDashboard.smallTitle")}
          title={t("myAccountDashboard.title")}
        />

        <PageWrapper>
          <BoxWrrapper>
            <BoxContent>
              <h2>
                {t("myAccountDashboard.basicDetails")} <span>*</span>
              </h2>
              <FormWrap style={{ marginBottom: "10px" }}>
                <TextInput
                  label={t("inputs.name")}
                  type={"text"}
                  value={name}
                  placeholder={t("placeholders.name")}
                  onChange={handleNameChange}
                />
                <TextInput
                  label={t("inputs.orgName")}
                  type={"text"}
                  value={organizationName}
                  placeholder={t("placeholders.orgName")}
                  onChange={handleOrganizationNameChange}
                />
                <DropdownSingle
                  title={t("inputs.position")}
                  options={positions}
                  value={selectedOption}
                  onChange={handleOptionChange}
                />
                <DropdownSingle
                  title={t("inputs.country")}
                  options={country}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                />
              </FormWrap>

              <Info>{t("myAccountDashboard.autoSave")}</Info>
            </BoxContent>
          </BoxWrrapper>
          <BoxWrrapper>
            <BoxContent>
              {" "}
              <h2>{t("myAccountDashboard.accDetails")}</h2>
              <FormWrap style={{ marginBottom: "10px" }}>
                <TextInput
                  label={t("inputs.email")}
                  type={"email"}
                  value={name}
                  placeholder={t("placeholders.email")}
                  onChange={handleEmailChange}
                  disabled
                />
                <FiedlWrapper>
                  <PasswordInput
                    label={t("inputs.password")}
                    placeholder={t("placeholders.password")}
                    value={formData.password || ""}
                    onChange={handlePasswordChange}
                  />
                  <ButtonLink
                    title={t("myAccountDashboard.editPassword")}
                    size={"small"}
                    color={"gray"}
                    underline
                    onClick={() => setOpen(!open)}
                  />
                </FiedlWrapper>
              </FormWrap>
            </BoxContent>
          </BoxWrrapper>
        </PageWrapper>
      </RightContent>
      {modal}
    </MainWrapper>
  );
};

export default Index;
