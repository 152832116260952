import React, { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import Filter, { FormWrap } from "../modal/Filter";
import Button from "../button/index";

// Images
import play from "../../images/ic_play.svg";
import playMatchBlue from "../../images/play_match_blue.svg";
import playActive from "../../images/ic_play_active.svg";
import playActiveBlue from "../../images/ic_play_active_blue.svg";
import playinball from "../../images/ic_playinball.svg";
import playInBallBlue from "../../images/playInBall_blue.svg";
import statistics from "../../images/ic_statistics.svg";
import statisticsBlue from "../../images/statistics_blue.svg";
import formation from "../../images/ic_formation.svg";
import formationBlue from "../../images/formation_blue.svg";
import moreDots from "../../images/ic_more.svg";
import playInBall from "../../images/ic_playinball.svg";

// Componenst
import Empty from "../../components/empty/index";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Language
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Moment from "react-moment";
import { breakpoints } from "../../style/size";
import {
  Action,
  ButtonFilter,
  MatchContent,
  MatchInfo,
} from "../../style/MatchTable";
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";
import TextInput, { InputWrapper } from "../inputs/TextInput";
import { $apiV1 } from "../../config/api";
import { Formation } from "../Formation";
import { NotificationList } from "../notification/NotificationList";
import { useNotification } from "../notification/useNotification";
import { getTeamParamMarkers } from "../../helpers";

export const MatchWrap = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  // height: 471px;
  height: fit-content;
  margin-bottom: 30px;
`;

export const TeamStats = styled.div`
  display: flex;
  flex-direction: row;

  &:nth-child(even) {
    background: ${(props) => props.theme.black};
  }
`;

export const TeamScore = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`;

export const TeamOptions = styled.div`
  width: 20%;
  background: ${(props) => props.theme.lines};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }
`;

export const Home = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 0 40px;

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  &.awayTeam {
    justify-content: end;
  }

  img {
    width: 40px;
    height: 40px;
  }

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    max-width: 100px;
    width: 100px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Score = styled.div`
  width: 20%;
  text-align: center;

  &.scorePlayerStat {
    width: 80%;
    text-align: left;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    margin-top: 10px;

    &.playerStatTitle {
      color: ${(props) => props.theme.white};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h3 {
    line-height: 0;
    time {
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiMedium";
      font-size: 12px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  h4 {
    margin-top: 10px;
    opacity: 1;
    font-family: "SatoshiBold";
    font-size: 20px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 2px;
    text-align: center;
    line-height: 28px;
    color: ${(props) => props.theme.white};
  }
`;

export const TeamScoreMobile = styled.div`
  padding: 10px;
  width: 100%;
  position: relative;

  h3 {
    opacity: 1;
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    line-height: 18px;
    time {
      font-family: "SatoshiMedium";
      color: rgba(115, 128, 137, 1);
    }
  }
`;

export const TopFunction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    transform: rotate(90deg);
    margin-right: 5px;
  }
`;

export const TeamScoreMobileContent = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
export const HomeResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    opacity: 1;
    color: rgba(54, 232, 45, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: right;
    line-height: 24px;
    color: ${props => props.theme.white};
  }
`;
export const AwayResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    opacity: 1;
    color: rgba(54, 232, 45, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: right;
    line-height: 24px;
    color: ${props => props.theme.white};
  }
`;
export const TeamMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: -40px;
  margin-bottom: 20px;
`;

export const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const OpenedDropdown = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  margin-top: 20px;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  max-height: 200px;
  overflow: scroll;

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: #00000012;
    }
  }
`;

const Matches = ({ table, onChangeData, season, playerTeams }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [openRow, setOpenRow] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [choosenSeasson, setChoosenSeasson] = useState(season);
  const [availableSeasons, setAvailableSeason] = useState("");
  const [competitions, setCompetitions] = useState([]);
  const [choosenCompetition, setChoosenCompetition] = useState("");
  const [filteredMatches, setFilteredMatches] = useState(table);
  const [mathcesList, setMatchesList] = useState("");
  const [lastMatches, SetLastMatches] = useState("");
  const [loading, setLoading] = useState(false);

  //formation state
  const [openFormation, setOpenFormation] = useState(false);
  const [selectedFormationMatch, setSelectedFormationMatch] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openCompetition, setOpenCompetition] = useState(false);
  const [openMatchesCount, setOpenMatchesCount] = useState(false);

  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedCompetition, setSelectedCompetition] = useState("");
  const [selectedMatch, setSelectedMatch] = useState("");

  const { notifications, addNotification, removeNotification } = useNotification();

  const handleCloseFormation = () => {
    setOpenFormation(false);
    setSelectedFormationMatch(null);
  }

  const handleOpenFormation = (match) => {
    setSelectedFormationMatch(match);
    setOpenFormation(true);
  }

  const handleClearFilter = () => {
    setChoosenSeasson();
    setSelectedSeason("");
    setCompetitions();
    setSelectedCompetition("");
  };

  const handleSeasonChoose = (item) => {
    setChoosenSeasson(item.id);
    setSelectedSeason(item.years);
    setOpen(false);
  };

  const handleCompetitionChoose = (item) => {
    setChoosenCompetition(item.competition_id);
    setSelectedCompetition(item.competition);
    setOpenCompetition(false);
  };

  const handleMatches = (item) => {
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
  };

  const setMoreInfo = (item) => {
    setOpenRow(openRow === item.id ? null : item.id);
  };

  const handleSeasons = async () => {

    try {
      setLoading(true);
      const response = await $apiV1.get(`/statistics/player/${id}?year_season_id=${choosenSeasson}`);
      onChangeData?.(response.data);
      setAvailableSeason(response.data.available_seasons);
      setCompetitions(response.data.matches_list);

      const filteredMatches = response?.data?.matches_list
        ?.filter((match) => choosenCompetition ? match.competition_id === choosenCompetition : true)
        .map((match) => match.id);

      setMatchesList(filteredMatches);

      // Filter matches to show based on the updated list
      // const matchesToShow = response.data.matches_list.filter((match) =>
      //   filteredMatches.includes(match.id)
      // );
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSeasons();
  }, [choosenSeasson, choosenCompetition]);

  const uniqueCompetitions = [];
  const seenNames = new Set();

  competitions.forEach((item) => {
    if (!seenNames.has(item.competition)) {
      seenNames.add(item.competition);
      uniqueCompetitions.push(item);
    }
  });

  const handleSearch = async () => {
    if (mathcesList.length > 0) {
      const updatedFilteredMatches = table.filter((match) =>
        mathcesList.includes(match.id)
      );
      setFilteredMatches(updatedFilteredMatches); // Update filtered matches
    }
    setOpenFilter(false);
  };

  const matches = [
    {
      id: 1,
      value: filteredMatches?.length,
      title: "All matches",
    },
    {
      id: 2,
      value: 3,
      title: "Last 3 matches",
    },
    {
      id: 3,
      value: 5,
      title: "Last 5 matches",
    },
    {
      id: 4,
      value: 10,
      title: "Last 10 matches",
    },
  ];

  const currentSelectedSeason = useMemo(() => {
    if (selectedSeason) return selectedSeason;
    if (availableSeasons?.length) {
      const foundedSeason = availableSeasons?.find(item => item.id == season)
      if (season && foundedSeason) {
        return foundedSeason?.years;
      }
    }
    return "Select Season";
  }, [season, selectedSeason, availableSeasons])

  const handleClickMatchParam = async (param_id, home_team_id, away_team_id, new_tab) => {
    try {
      const controller = new AbortController();
      addNotification(`Wow that’s a lot of match clips. I’m fetching them for you`, 'info', controller)
      document.body.classList.add('loading-cursor');
      const dataHome = await getTeamParamMarkers(home_team_id, param_id, 'in_attack', choosenSeasson, controller);
      const dataAway = await getTeamParamMarkers(away_team_id, param_id, 'in_attack', choosenSeasson, controller);
      if (dataHome?.results?.length > 0 || dataAway?.results?.length > 0) {
        const markers = [...dataHome.results.map(item => item.id), ...dataAway.results.map(item => item.id)].join(',');
        new_tab
          ? window.open(`/tournaments/match/video?markers=${markers}`, '_blank')
          : navigate(`/tournaments/match/video?markers=${markers}`)
      } else {
        addNotification(`There are 0 clips in the response from the server.`, 'warning')
      }
    } catch (e) {
      console.log('error get param markers', e);
      if (e.type === 'canceled') {
        addNotification(`The request to receive clips of the match has been canceled.`, 'warning')
        return
      }
      addNotification(`Error in receiving match events. ${e.message}`, 'error')
    } finally {
      document.body.classList.remove('loading-cursor');
    }
  }

  const getColorScore = (item) => {
    const isTeamHome = playerTeams?.map(item => item.name)?.includes(item.home_team);
    if (isTeamHome) {
      return item.home_team_score > item.away_team_score
        ? "#36E82D"
        : item.home_team_score < item.away_team_score
          ? "#F20A46"
          : "#E8D02D";
    } else {
      return item.home_team_score > item.away_team_score
        ? "#F20A46"
        : item.home_team_score < item.away_team_score
          ? "#36E82D"
          : "#E8D02D";
    }
  }

  return (
    <>
      {table.length > 0 ? (
        <div>
          <ButtonWrapper>
            <ButtonFilter
              className="buttonFilter"
              onClick={() => setOpenFilter(!openFilter)}
            >
              {t("smrtSearch.filter")}
            </ButtonFilter>
          </ButtonWrapper>
          <MatchWrap>
            {filteredMatches
              ?.sort((a, b) => new Date(a.date) - new Date(b.date))
              ?.slice(0, lastMatches ? lastMatches : table.length)
              .map((item, index) => {
                return (
                  <TeamStats key={index}>
                    {!isMobile && (
                      <TeamScore>
                        <Home>
                          <img
                            src={item?.home_team_logo}
                            alt={item?.home_team_logo}
                          />{" "}
                          <h4 data-tooltip-id={`full-name-${item?.home_team}`}>
                            {item?.home_team}
                          </h4>
                          <ReactTooltip
                            id={`full-name-${item.home_team}`}
                            place="bottom"
                            content={item?.home_team}
                          />
                        </Home>
                        <Score>
                          <h3>
                            <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                          </h3>
                          <h4
                            style={{
                              color: getColorScore(item),
                            }}
                          >
                            {item.score}
                          </h4>
                        </Score>
                        <Home className="awayTeam">
                          <img
                            src={item?.away_team_logo}
                            alt={item?.away_team_logo}
                          />{" "}
                          <h4 data-tooltip-id={`full-name-${item?.away_team}`}>
                            {item?.away_team}
                          </h4>
                          <ReactTooltip
                            id={`full-name-${item.away_team}`}
                            place="bottom"
                            content={item?.away_team}
                          />
                        </Home>
                      </TeamScore>
                    )}
                    {isMobile && (
                      <TeamScoreMobile>
                        <TeamScoreMobileContent>
                          <TopFunction>
                            {" "}
                            <h3>
                              <Moment format="DD.MM.YYYY">{item?.date}</Moment>
                            </h3>
                            <img
                              src={moreDots}
                              alt={moreDots}
                              onClick={() => setMoreInfo(item)}
                            />
                          </TopFunction>
                          <HomeResult>
                            <TeamMobile>
                              <img
                                src={item?.home_team_logo}
                                alt={item?.home_team_logo}
                              />{" "}
                              <h4>{item?.home_team}</h4>
                            </TeamMobile>
                            {openRow === item.id && (
                              <MatchInfo style={{ top: "30%", right: "10px" }}>
                                <MatchContent>
                                  <Action
                                    onClick={() => {
                                      if (item?.videos.length > 0) {
                                        window.open(
                                          `/tournaments/match/video/${item.id}`,
                                          "_blank"
                                        );
                                      }
                                    }}
                                  >
                                    <img
                                      src={
                                        item?.videos.length > 0
                                          ? localStorage.getItem("theme") ===
                                            "light"
                                            ? playActiveBlue
                                            : playActive
                                          : playActive
                                      }
                                      alt={
                                        localStorage.getItem("theme") ===
                                        "light"
                                          ? playMatchBlue
                                          : play
                                      }
                                    />
                                    <h4>Play Full Match</h4>
                                  </Action>
                                  <Action
                                    onClick={() =>
                                      handleClickMatchParam(
                                        14,
                                        item?.home_team_id,
                                        item?.away_team_id
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        localStorage.getItem("theme") ===
                                        "light"
                                          ? playInBallBlue
                                          : playInBall
                                      }
                                      alt={playInBall}
                                    />
                                    <h4>OBE</h4>
                                  </Action>
                                  <Action
                                    onClick={() =>
                                      window.open(
                                        `/tournaments/statistics/${item.id}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        localStorage.getItem("theme") ===
                                        "light"
                                          ? statisticsBlue
                                          : statistics
                                      }
                                      alt={statistics}
                                    />
                                    <h4>See Match Statistics</h4>
                                  </Action>
                                  <Action
                                    onClick={() => {
                                      handleOpenFormation(item);
                                    }}
                                  >
                                    <img
                                      src={
                                        localStorage.getItem("theme") ===
                                        "light"
                                          ? formationBlue
                                          : formation
                                      }
                                      alt={formation}
                                    />
                                    <h4>See Match Formation</h4>
                                  </Action>
                                </MatchContent>
                              </MatchInfo>
                            )}

                            <h5
                              style={{
                                color: getColorScore(item),
                              }}
                            >
                              {item?.home_team_score}
                            </h5>
                          </HomeResult>
                          <AwayResult>
                            <TeamMobile>
                              <img
                                src={item?.away_team_logo}
                                alt={item?.away_team_logo}
                              />{" "}
                              <h4>{item?.away_team}</h4>
                            </TeamMobile>
                            <h5
                              style={{
                                color: getColorScore(item),
                              }}
                            >
                              {item?.home_team_score}
                            </h5>
                          </AwayResult>
                        </TeamScoreMobileContent>
                      </TeamScoreMobile>
                    )}
                    {!isMobile && (
                      <TeamOptions>
                        <img
                          src={
                            item?.videos.length > 0
                              ? localStorage.getItem("theme") === "light"
                                ? playActiveBlue
                                : playActive
                              : playActive
                          }
                          alt={play}
                          onClick={() => {
                            if (item?.videos.length > 0) {
                              navigate(`/tournaments/match/video/${item.id}`);
                            }
                          }}
                          onContextMenu={() => {
                            if (item?.videos.length > 0) {
                              window.open(
                                `/tournaments/match/video/${item.id}`,
                                "_blank"
                              );
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          data-tooltip-id="play-full-match"
                        />
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? playInBallBlue
                              : playinball
                          }
                          alt={playinball}
                          data-tooltip-id="play-ball-in-play"
                          onClick={() =>
                            handleClickMatchParam(
                              14,
                              item?.home_team_id,
                              item?.away_team_id
                            )
                          }
                          onContextMenu={() =>
                            handleClickMatchParam(
                              14,
                              item?.home_team_id,
                              item?.away_team_id,
                              true
                            )
                          }
                        />
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? statisticsBlue
                              : statistics
                          }
                          alt={statistics}
                          onClick={() =>
                            navigate(`/tournaments/statistics/${item.id}`)
                          }
                          onContextMenu={() =>
                            window.open(
                              `/tournaments/statistics/${item.id}`,
                              "_blank"
                            )
                          }
                          style={{ cursor: "pointer" }}
                          data-tooltip-id="see-match-statistics"
                        />
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? formationBlue
                              : formation
                          }
                          alt={formation}
                          data-tooltip-id="see-match-formation"
                          onClick={() => handleOpenFormation(item)}
                          onContextMenu={() => handleOpenFormation(item)}
                        />

                        <ReactTooltip
                          id="play-full-match"
                          place="bottom"
                          content="Play Full Match"
                        />
                        <ReactTooltip
                          id="play-ball-in-play"
                          place="bottom"
                          content="OBE"
                        />
                        <ReactTooltip
                          id="see-match-statistics"
                          place="bottom"
                          content="See Match Statistics"
                        />
                        <ReactTooltip
                          id="see-match-formation"
                          place="bottom"
                          content="See Match Formation"
                        />
                      </TeamOptions>
                    )}
                  </TeamStats>
                );
              })}
          </MatchWrap>
          <Filter
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            onClick={() => handleClearFilter()}
          >
            <FormWrap style={{ marginBottom: "20px" }}>
              {availableSeasons && (
                <DropdownWrapper>
                  <div style={{ position: "relative" }}>
                    <TextInput
                      type="text"
                      value={currentSelectedSeason}
                      onClick={() => setOpen(!open)}
                      readOnly
                    />

                    <img
                      src={open ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      onClick={() => setOpen(!open)}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {open && (
                    <OpenedDropdown>
                      {availableSeasons?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => handleSeasonChoose(item)}
                        >
                          {item.years}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>
              )}

              {competitions && (
                <DropdownWrapper>
                  <div style={{ position: "relative" }}>
                    <TextInput
                      type="text"
                      value={
                        selectedCompetition.length == 0
                          ? "Select Competition"
                          : selectedCompetition
                      }
                      onClick={() => setOpenCompetition(!openCompetition)}
                      readOnly
                    />

                    <img
                      src={openCompetition ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      onClick={() => setOpenCompetition(!openCompetition)}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {openCompetition && (
                    <OpenedDropdown>
                      {uniqueCompetitions?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => handleCompetitionChoose(item)}
                        >
                          {item.competition}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>
              )}

              <DropdownWrapper>
                <div style={{ position: "relative" }}>
                  <TextInput
                    type="text"
                    value={
                      selectedMatch.length == 0 ? "PRE-SET" : selectedMatch
                    }
                    onClick={() => setOpenMatchesCount(!openMatchesCount)}
                    readOnly
                  />

                  <img
                    src={openMatchesCount ? arrowIconUp : arrowIconDown}
                    alt={arrowIconDown}
                    onClick={() => setOpenMatchesCount(!openMatchesCount)}
                    style={{
                      color: "#fff",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  />
                </div>

                {openMatchesCount && (
                  <OpenedDropdown>
                    {matches?.map((item, index) => (
                      <div key={index} onClick={() => handleMatches(item)}>
                        {item.title}
                      </div>
                    ))}
                  </OpenedDropdown>
                )}
              </DropdownWrapper>
            </FormWrap>

            {loading ? (
              <Button title="fetching data" disabled size={"full"} />
            ) : (
              <Button
                title="search"
                onClick={() => handleSearch()}
                size={"full"}
              />
            )}
          </Filter>

          <Formation
            open={openFormation}
            handleClose={handleCloseFormation}
            match={selectedFormationMatch}
          />
        </div>
      ) : (
        <Empty title="No matches found" />
      )}

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </>
  );
};

export default Matches;
