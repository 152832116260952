import React, { useState } from "react";
import { $apiV1 } from "../../config/api";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Style
import {
  TopTableNames,
  TableNameWrap,
  TableWrap,
  TableContent,
  Player,
  Position,
  Club,
  Passes,
  RightContent,
  SeeMoreWrap,
  Compare,
  ProfileImage,
  ImagesWrap,
  FilterButton,
  TableNameWrapSmrtSearch,
} from "../../style/SmrtSearch";

// Images
import filer from "../../images/ic_filter.svg";
import emptyState from "../../images/contactLogo.svg";
import starOn from "../../images/star_on.svg";
import starOff from "../../images/star_off.svg";
import compareBtn from "../../images/ic_compare.svg";
import compareBlue from "../../images/compare_blue.svg";

// Componenst
import Empty from "../empty/index";
import Filter from "../modal/Filter";
import Modal from "../../components/modal/index";
import Button from "../button";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageButton, PaginationWrapper } from "../../style/Favorites";
import { Oval } from "react-loader-spinner";

const SmrtSearchSidebar = ({
  table,
  page,
  totalPages,
  onPageChange,
  loadingSearch,
}) => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const { t, i18n } = useTranslation();

  // State to track favorite status for each player by their ID
  const [favoritePlayers, setFavoritePlayers] = useState({});

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const navigate = useNavigate();

  const tableNames = [
    {
      id: 1,
      name: `${t("smrtSearch.player")}`,
    },
    {
      id: 2,
      name: `${t("smrtSearch.position")}`,
    },
    {
      id: 3,
      name: `${t("smrtSearch.club")}`,
    },
    {
      id: 4,
      name: `${t("smrtSearch.passes")}`,
    },
    {
      id: 5,
      name: `Passes + %`,
    },
    {
      id: 6,
      name: `${t("smrtSearch.goals")}`,
    },
    {
      id: 7,
      name: `${t("smrtSearch.assists")}`,
    },
    {
      id: 8,
      name: `${t("smrtSearch.mist")}`,
    },
    {
      id: 9,
      name: `Duels won %`,
    },
    {
      id: 10,
      name: `Crosses`,
    },
    {
      id: 11,
      name: `${t("smrtSearch.rc")}`,
    },
    {
      id: 12,
      name: `${t("smrtSearch.yc")}`,
    },
  ];

  const toggleFavorite = async (item) => {
    try {
      const isCurrentlyFavorite = favoritePlayers[item.id] ?? false;

      if (isCurrentlyFavorite) {
        await $apiV1.delete(`/users/favorites/players/${item.id}/`);
      } else {
        await $apiV1.post(`/users/favorites/players/`, { player: item.id });
      }

      setFavoritePlayers((prev) => ({
        ...prev,
        [item.id]: !isCurrentlyFavorite,
      }));
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  const [successPopup, setSuccessPopup] = useState(false);

  const handleCompare = (item) => {
    // Retrieve the players array from local storage
    const storedPlayers = localStorage.getItem("players");
    let playersArray = [];

    if (storedPlayers) {
      // Parse the existing players array from local storage
      playersArray = JSON.parse(storedPlayers);
    }

    // Check if the item ID is already in the array
    if (!playersArray.includes(item.id)) {
      // Add the new player ID to the array
      playersArray.push(item.id);
      // Store the updated array back in local storage
      localStorage.setItem("players", JSON.stringify(playersArray));
    }

    setSuccessPopup(!successPopup);
  };

  const modal = (
    <>
      {/* Success added to compare  */}
      <Modal isOpen={successPopup} setIsOpen={setSuccessPopup} closeIc>
        <h1>Successfully added to compare</h1>
        <br />
        <Button
          title="Go to compare"
          size={"full"}
          onClick={() => navigate(`/compare`)}
        />
      </Modal>
    </>
  );

  return (
    <>
      {table?.length > 0 ? (
        <>
          {!isMobile && (
            <TopTableNames>
              {tableNames.map((item, index) => {
                return (
                  <TableNameWrapSmrtSearch title={item.name} key={index}>
                    <h5
                      data-tooltip-id={`full-name-${item.name}`}
                    >
                      {item.name}
                    </h5>
                    <ReactTooltip
                      id={`full-name-${item.name}`}
                      place="bottom"
                      content={`${item.name}`}
                    />
                  </TableNameWrapSmrtSearch>
                );
              })}
            </TopTableNames>
          )}
          <TableWrap>
            {loadingSearch ? (
              <Oval
                secondaryColor={
                  localStorage.getItem("theme") === "light"
                    ? "#4E5255"
                    : "#CFDAE6"
                }
                wrapperStyle={{
                  height: "60vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : (
              <>
                {table.map((item, index) => {
                  const isFavorite = favoritePlayers[item.id] ?? false;

                  return (
                    <TableContent key={index}>
                      <Player style={{ color: "#fff" }}>
                        <ImagesWrap>
                          <img
                            src={isFavorite ? starOn : starOff}
                            alt="Favorite star"
                            style={{
                              width: "18px",
                              height: "100%",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleFavorite(item)}
                          />
                          <ProfileImage>
                            <img
                              style={{ width: "40px", height: "40px;" }}
                              src={
                                item.photos.length > 0
                                  ? `https://platform.smrtstats.com:8888/${item.photos[0].image}`
                                  : emptyState
                              }
                              alt={emptyState}
                            />
                            <img
                              className="flag"
                              src={
                                item.nationality.length > 0
                                  ? item.nationality[0].flag
                                  : ""
                              }
                            />
                          </ProfileImage>
                        </ImagesWrap>
                        <h3
                          data-tooltip-id={`full-name-${item.name}-${item.surname}`}
                        >
                          {item.name} {item.surname}
                        </h3>
                        <ReactTooltip
                          id={`full-name-${item.name}-${item.surname}`}
                          place="bottom"
                          content={`${item.name} ${item.surname}`}
                        />
                      </Player>

                      {!isMobile && (
                        <>
                          {item.position && (
                            <Position className="smrtSearchPosition">
                              <h3
                                data-tooltip-id={`full-name-${item.position?.[0]?.name}`}
                              >
                                {item.position?.[0]?.name} {""}
                                {item.position.length - 1 === 0
                                  ? ""
                                  : `+ ${item.position.length - 1}`}
                              </h3>
                              <ReactTooltip
                                id={`full-name-${item.position?.[0]?.name}`}
                                place="bottom"
                                content={item.position.map(
                                  (positionItem, positionIndex) => {
                                    return (
                                      <ul key={positionIndex}>
                                        <li>
                                          {positionItem?.short_name ?? "/"}
                                        </li>
                                      </ul>
                                    );
                                  }
                                )}
                              />
                            </Position>
                          )}
                          <Club>
                            <h3 data-tooltip-id={`team-${item?.team?.name}`}>
                              {item.team ? item.team.name : "/"}
                            </h3>
                            <ReactTooltip
                              id={`team-${item?.team?.name}`}
                              place="bottom"
                              content={item?.team?.name}
                            />
                          </Club>
                          <Passes>
                            <h3>{Math.round(item.params[1].count)}</h3>
                          </Passes>
                          <Passes>
                            <h3>{Math.round(item.params[2].count)}</h3>
                          </Passes>
                          <Passes>
                            <h3>{Math.round(item.params[6].count)}</h3>
                          </Passes>
                          <Passes>
                            <h3>{Math.round(item.params[8].count)}</h3>
                          </Passes>
                          <Passes>
                            <h3>{Math.round(item.params[5].count)}</h3>
                          </Passes>
                          <Passes>
                            <h3>{Math.round(item.params[4].count)}</h3>
                          </Passes>
                          <Passes>
                            <h3>{Math.round(item.params[3].count)}</h3>
                          </Passes>
                          <Passes>
                            <h3>{Math.round(item.params[7].count)}</h3>
                          </Passes>
                          <Passes>
                            <h3>{Math.round(item.params[0].count)}</h3>
                          </Passes>
                        </>
                      )}
                      <RightContent>
                        <SeeMoreWrap
                          onClick={() =>
                            navigate(`/tournaments/player/${item.id}`)
                          }
                        >
                          {t("smrtSearch.seeMore")}
                        </SeeMoreWrap>
                        <img
                          onClick={() => handleCompare(item)}
                          src={
                            localStorage.getItem("theme") === "light"
                              ? compareBlue
                              : compareBtn
                          }
                          alt={compareBtn}
                          style={{ cursor: "pointer" }}
                        />
                      </RightContent>
                    </TableContent>
                  );
                })}
              </>
            )}
          </TableWrap>
          <PaginationWrapper>
            <PageButton onClick={() => handlePageChange(page - 1)}>
              Previous page
            </PageButton>
            <span>
              {page} / {totalPages}
            </span>
            <PageButton onClick={() => handlePageChange(page + 1)}>
              Next page
            </PageButton>
          </PaginationWrapper>
          {modal}
        </>
      ) : (
        <Empty
          title={t("smrtSearch.emptySearchTitle")}
          text={t("smrtSearch.emptySearchDesc")}
        />
      )}
    </>
  );
};

export default SmrtSearchSidebar;
