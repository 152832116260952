import React from "react";
import styled, { css } from "styled-components";
import { breakpoints } from "../../style/size";

// Styled components
const CheckboxAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  position: relative;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) =>
    props.hasBackground ? props.theme.lines : "transparent"};
  height: 44px;
  width: ${(props) => (props.hasBackground ? "45%" : "")};
  align-items: center;
  padding-left: ${(props) => (props.hasBackground ? "10px" : "")};

  @media only screen and (${breakpoints.mobile}) {
    width: ${(props) => (props.hasBackground ? "100%" : "")};
  }

  label {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding-left: 5px;
    display: flex;
    align-items: center;
  }

  /* Checked label text color */
  input:checked + label {
    opacity: 1;
    font-family: "SatoshiBold";
    color: ${(props) => props.theme.blueish};
  }
`;

const CheckboxInput = styled.input`
  /* Hide default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;

  /* Style custom checkbox */
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.blueish2};
  border-radius: 3px;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: transparent;
    border-color: ${(props) => props.theme.green};
  }

  &:hover {
    border-color: ${(props) => props.theme.blueish};
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    width: 4px;
    height: 8px;
    border: solid ${(props) => props.theme.green};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:checked:after {
    display: block;
  }
`;

// Checkbox component
const Checkbox = ({ label, checked, onChange, hasBackground = true }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <CheckboxAreaWrapper hasBackground={hasBackground}>
      <CheckboxInput
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        hasBackground={hasBackground}
      />
      <label>{label}</label>
    </CheckboxAreaWrapper>
  );
};

export default Checkbox;
