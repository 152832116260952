import React, { useState } from "react";

// Style
import {
  AuthWrapper,
  FormWrapper,
  ContentReset,
  ImageWrapper,
  Form,
  ConfirmWrapp,
} from "../../style/Auth";

// Images
import logo from "../../images/ic_logo.svg";

// Components
import PasswordInput from "../../components/inputs/PasswordInput";
import Button from "../../components/button/index";
import ButtonLink from "../../components/link/ButtonLink";

const PasswordReset = () => {
  const [formData, setFormData] = useState({});

  const handlePasswordChange = (value) => {
    setFormData({ ...formData, password: value });
  };

  const handleConfirmPasswordChange = (value) => {
    setFormData({ ...formData, confirmPassword: value });
  };

  return (
    <AuthWrapper>
      <FormWrapper>
        <ContentReset>
          <Form>
            <img className="logo" src={logo} alt={logo} />

            <h1>Set Up the New Password</h1>
            <p>
              Enter password according to rules, and repeat it. After that, you
              will be logged into SmrtStats.
            </p>

            <PasswordInput
              label="Password:"
              placeholder="Enter your password"
              value={formData.password || ""}
              onChange={handlePasswordChange}
              bottomText="Password must contain at least 1 symbol, and 1 number."
              // error={passwordError}
            />
            <PasswordInput
              label="Confirm Password:"
              placeholder="Enter your password"
              value={formData.confirmPassword || ""}
              onChange={handleConfirmPasswordChange}
              // error={passwordError}
            />

            <ConfirmWrapp>
              <Button title="log in" size={"full"} sizeResponse={"full"} />
            </ConfirmWrapp>
          </Form>
        </ContentReset>
      </FormWrapper>
      <ImageWrapper></ImageWrapper>
    </AuthWrapper>
  );
};

export default PasswordReset;
