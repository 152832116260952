import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Style
import {
  Wrapper,
  Languages,
  Navigation,
  Content,
  WrapperMobile,
  MobileContent,
  MobileRightElements,
  Overlay,
  OpenMobileMenu,
} from "../../style/LandingNavigation";

// Images
import logo from "../../images/ic_logo.svg";
import hamburger from "../../images/ic_menu.svg";
import closeHamburger from "../../images/ic_close.svg";

// Components
import Button from "../button";

// Language
import { useTranslation } from "react-i18next";

const LandingNavigation = () => {
  const { t, i18n } = useTranslation();
  const [currectLang, setCurrentLang] = useState(
    localStorage.getItem("lang") || "en"
  );

  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState(false);

  const handleLink = () => {
    localStorage.getItem('token')
      ? navigate("/dashboard")
      : navigate("/login");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lang", lng);
    setCurrentLang(lng);
  };

  const languages = (
    <Languages>
      <h4
        className={currectLang === "en" ? "active" : "inactive"}
        onClick={() => changeLanguage("en")}
      >
        ENG
      </h4>
      <span>/</span>
      <h4
        className={currectLang === "es" ? "active" : "inactive"}
        onClick={() => changeLanguage("es")}
      >
        ESP
      </h4>
    </Languages>
  );

  const desktopNavigation = (
    <Wrapper>
      {languages}
      <Navigation>
        <img src={logo} alt={logo} />
        <Content>
          <ul>
            <li>
              <a href="/#platform">{t("navigation.platrfom")}</a>
            </li>
            <li>
              <a href="/#services">{t("navigation.services")}</a>
            </li>
            <li>
              <a href="/#about">{t("navigation.aboutUs")}</a>
            </li>
            <li>
              <a href="/#contact">{t("navigation.contactUs")}</a>
            </li>
          </ul>
        </Content>
        <Button
          title={t("navigation.button")}
          size={"normal"}
          color={"secondary"}
          onClick={() => handleLink()}
        />
      </Navigation>
    </Wrapper>
  );

  const mobileNavigation = (
    <WrapperMobile>
      <MobileContent>
        <img src={logo} alt={logo} />
        <MobileRightElements>
          {!openMenu ? (
            <>
              {languages}
              <img
                src={hamburger}
                alt={hamburger}
                onClick={() => setOpenMenu(!openMenu)}
              />
            </>
          ) : (
            <img
              src={closeHamburger}
              alt={closeHamburger}
              onClick={() => setOpenMenu(!openMenu)}
            />
          )}
        </MobileRightElements>
      </MobileContent>

      {openMenu && (
        <Overlay>
          <OpenMobileMenu>
            <ul>
              <li>
                <a href="/#platform" onClick={() => setOpenMenu(false)}>
                  {t("navigation.platrfom")}
                </a>
              </li>
              <li>
                <a href="/#services" onClick={() => setOpenMenu(false)}>
                  {t("navigation.services")}
                </a>
              </li>
              <li>
                <a href="/#about" onClick={() => setOpenMenu(false)}>
                  {t("navigation.aboutUs")}
                </a>
              </li>
              <li>
                <a href="/#contact" onClick={() => setOpenMenu(false)}>
                  {t("navigation.contactUs")}
                </a>
              </li>
            </ul>
            <Button
              title={t("navigation.button")}
              size={"normal"}
              color={"secondary"}
              onClick={() => handleLink()}
            />
          </OpenMobileMenu>
        </Overlay>
      )}
    </WrapperMobile>
  );

  return <>{!isMobile ? desktopNavigation : mobileNavigation}</>;
};

export default LandingNavigation;
