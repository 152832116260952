import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";
import { useNavigate } from "react-router-dom";

// Style
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
  FilterContentWrap,
} from "../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";
import {
  CompanreButton,
  IntroTopRight,
  IntroTopSearch,
  SearchWrap,
} from "../../style/SmrtSearch";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import Button from "../../components/button";

// Images
import emptySearch from "../../images/ic_empty.svg";
import searchIcon from "../../images/ic_search.svg";
import arrowIconUp from "../../images/up.svg";
import arrowIconDown from "../../images/down.svg";

// Language
import { useTranslation } from "react-i18next";

// Axios
import SmrtSearchSidebar from "../../components/table/SmrtSearchSidebar";
import { EmptyState } from "../../style/Tournaments";
import Filter from "../../components/modal/Filter";
import { InputWrapper } from "../../components/inputs/TextInput";
import {
  OpenedDropdown,
  ParamCheckboxStyle,
} from "../../components/table/MatchTable";
import styled from "styled-components";
import { $apiV1, $apiV2 } from "../../config/api";

const fetchData = async (url, params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await fetch(`${url}?${queryString}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const AgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }

  > div {
    width: 100%;
  }
`;

const Index = () => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [openFilter, setOpenFilter] = useState(false);
  const [gender, setGender] = useState("");
  const [search, setSearch] = useState("");
  const [olderAge, setOlderAge] = useState("");
  const [underAge, setUnderAge] = useState("");
  const [heightFrom, setHeightFrom] = useState("");
  const [heightTo, setHeightTo] = useState("");
  const [minPlayedFrom, setMinPlayerdFrom] = useState("");
  const [minPlayedTo, setMinPlayedTo] = useState("");
  const [goalsFrom, setGoalsFrom] = useState("");
  const [goalsTo, setGoalsTo] = useState("");
  const [assistFrom, setAssistFrom] = useState("");
  const [assistTo, setAssistTo] = useState("");
  const [passAccuracyFrom, setPassAccuracyFrom] = useState("");
  const [passAccuracyTo, setPassAccuracyTo] = useState("");
  const [duelsWonFrom, setDuelsWonFrom] = useState("");
  const [duelsWonTo, setDuelsWonTo] = useState("");
  const [crossesFrom, setCrossesFrom] = useState("");
  const [crossesTo, setCrossesTo] = useState("");

  const [teamType, setTeamType] = useState("");
  const [leg, setLeg] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [text, setText] = useState("");
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [openPositions, setOpenPositions] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [openCompetition, setOpenCompetition] = useState(false);

  const [countryName, setCountryName] = useState("");
  const [country, setCountry] = useState([]);
  const [selectedContry, setSelectedCountry] = useState("");

  const [competitionName, setCompetitionName] = useState("");
  const [competition, setCompetition] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState("");

  const [playerSearchResults, setPlayerSearchResults] = useState([]);

  const [genderName, setGenderName] = useState("Select Gender");
  const [selectedGender, setSelectedGender] = useState("");
  const [openGender, setOpenGender] = useState(false);

  const [legName, setLegName] = useState("Select Leg");
  const [selectedLeg, setSelectedLeg] = useState("");
  const [openLeg, setOpenLeg] = useState(false);

  const [teamTypeName, setTeamTypeName] = useState("Select Team Type");
  const [selectSeason, setSelectSeason] = useState([]);
  const [pageSeason, setPageSeason] = useState(1);
  const [selectedSeason, setSelectedSeason] = useState("")
  const [selectedTeamType, setSelectedTeamType] = useState("");
  const [openTeamType, setOpenTeamType] = useState(false);
  const [openSeason, setOpenSeason] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const playersPerPage = 10; // Number of players per page

  const [loadingSearch, setLoadingSearch] = useState(true);

  const getSeason = async (page = 1) => {
    try {
      const response = await $apiV1.get(`/platform/year_season/`, { params: { page } });
      setSelectSeason(prev => ([...prev, ...response.data.results]));
      setPageSeason(page);
      console.log(response.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  useEffect(() => {
    getSeason();
    getPositions();
  }, []);

  const getPositions = async () => {
    try {
      const response = await $apiV1.get(`/platform/position/`, {
        params: {
          search: text,
        },
      });
      setPositions(response.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getCountry = async () => {
    try {
      const response = await $apiV1.get(`/platform/nationality/`, {
        params: {
          search: countryName,
        },
      });
      setCountry(response.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getCompetion = async () => {
    try {
      const response = await $apiV1.get(`/platform/competition/`, {
        params: {
          search: competitionName,
        },
      });
      setCompetition(response.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (text) {
      getPositions();
    }

    if (countryName) {
      getCountry();
    }

    if (competitionName) {
      getCompetion();
    }
  }, [text, countryName, competitionName]);

  const handleSearch = async (page) => {
    try {
      setLoadingSearch(true);
      const response = await $apiV2.get(`/platform/players/?`, {
        params: {
          params: '21,36,162,2,23,201,37,124,50',
          page: page,
          year_season: selectedSeason,
          gender: selectedGender,
          search: search,
          age_older: olderAge,
          age_under: underAge,
          team_type: selectedTeamType,
          leg: selectedLeg,
          position: selectedPosition,
          country: selectedContry,
          competition: selectedCompetition,
          height_less: heightFrom,
          height_larger: heightTo,
          mins_played_less: minPlayedFrom,
          mins_played_larger: minPlayedTo,
          goals_less: goalsFrom,
          goals_larger: goalsTo,
          assists_less: assistFrom,
          assists_larger: assistTo,
          passes_larger: passAccuracyFrom,
          passes_less: passAccuracyTo,
          duels_larger: duelsWonFrom,
          duels_less: duelsWonTo,
          crosses_larger: crossesFrom,
          crosses_less: crossesTo,
        },
      });
      setPlayerSearchResults(response.data.results);
      setTotalPlayers(response?.data?.count);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    handleSearch(page);
  }, [page]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalPlayers / playersPerPage)) {
      setPage(newPage);
    }
  };

  const genderOptions = [
    { id: "M", name: "Male" },
    { id: "F", name: "Female" },
  ];

  const legOptions = [
    { id: "R", name: "Right" },
    { id: "L", name: "Left" },
    { id: "RL", name: "Both" },
  ];

  const teamTypeOptions = [
    { id: "1", name: "Club" },
    { id: "2", name: "National" },
  ];

  const clearFilter = () => {
    setSearch("");
    setOlderAge("");
    setUnderAge("");
    setHeightFrom("");
    setHeightTo("");
    setMinPlayerdFrom("");
    setMinPlayedTo("");
    setGoalsFrom("");
    setGoalsTo("");
    setGoalsFrom(""); // Assuming this is a typo, remove duplicate line if unnecessary
    setAssistFrom("");
    setAssistTo("");
    setPassAccuracyFrom("");
    setPassAccuracyTo("");
    setDuelsWonFrom("");
    setDuelsWonTo("");
    setCrossesFrom("");
    setCrossesTo("");

    setLegName("");
    setSelectedLeg("");

    setGenderName("");
    setSelectedGender("");

    setCompetitionName("");
    setSelectedCompetition("");

    setTeamTypeName("");
    setSelectedTeamType("");

    setCountryName("");
    setSelectedCountry("");

    setText("");
    setSelectedPosition("");
  };

  const [selectSeasonLabel, setSelectSeasonLabel] = useState("");

  const handleSelectSeason = (item) => {
    setSelectSeasonLabel(item.years);
    setSelectedSeason(item.id)
  }

   const [navigationOpen, setNavigationOpen] = useState(
     localStorage.getItem("navigationOpen")
   );

   useEffect(() => {
     const handleNavigationOpenChange = () => {
       setNavigationOpen(localStorage.getItem("navigationOpen"));
       console.log(localStorage.getItem("navigationOpen"), "ss");
     };

     window.addEventListener(
       "navigationOpenChange",
       handleNavigationOpenChange
     );

     // Cleanup event listener on component unmount
     return () => {
       window.removeEventListener(
         "navigationOpenChange",
         handleNavigationOpenChange
       );
     };
   }, []);

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText
            smallTitle={t("smrtSearch.smallTitle")}
            title={t("smrtSearch.title")}
          />
          <IntroTopRight>
            <IntroTopSearch>
              {isMobile ? (
                <Button
                  onClick={() => navigate(`/compare`)}
                  title={t("smrtSearch.button")}
                  sizeResponse={"full"}
                  style={{ zIndex: 0 }}
                />
              ) : (
                <CompanreButton onClick={() => navigate(`/compare`)}>
                  {t("smrtSearch.button")}
                </CompanreButton>
              )}
            </IntroTopSearch>
          </IntroTopRight>
        </TopWrapper>

        <button
          className="buttonFilter"
          onClick={() => setOpenFilter(!openFilter)}
        >
          {t("smrtSearch.filter")}
        </button>

        <Filter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          style={{ zIndex: "1", position: "relatives" }}
          onClick={() => clearFilter()}
        >
          <FilterContentWrap>
            <InputWrapper onClick={() => setOpenSeason(!openSeason)}>
              <input
                type="text"
                value={selectSeasonLabel}
                placeholder="Select Season"
                readOnly
              />
              <img
                className="dropdownIcon"
                src={openSeason ? arrowIconUp : arrowIconDown}
                alt={openSeason ? "Collapse" : "Expand"}
              />
              <div style={{ position: "relative" }}>
                {openSeason && (
                  <OpenedDropdown>
                    {selectSeason.map((item, index) => {
                      return (
                        <ParamCheckboxStyle key={index}>
                          <input
                            type="checkbox"
                            checked={selectedSeason === item.id}
                            value={item.id}
                            onClick={(e) => handleSelectSeason(item)}
                          />
                          <label>{item.years}</label>
                        </ParamCheckboxStyle>
                      );
                    })}
                    {
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "0 auto",
                        }}
                        title="Load more"
                        onClick={(e) => {
                          e.stopPropagation();
                          getSeason(pageSeason + 1);
                        }}
                      />
                    }
                  </OpenedDropdown>
                )}
              </div>
            </InputWrapper>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={search}
                placeholder="Select Name"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputWrapper>
            <br />
            <InputWrapper onClick={() => setOpenPositions(!openPositions)}>
              <input
                type="text"
                value={text}
                placeholder="Type positions"
                onChange={(e) => {
                  setText(e.target.value);
                  if (e.target.value) {
                    setOpenPositions(true);
                  } else {
                    setOpenPositions(false);
                  }
                }}
              />
              <img
                className="dropdownIcon"
                src={openPositions ? arrowIconUp : arrowIconDown}
                alt={openPositions ? "Collapse" : "Expand"}
              />
            </InputWrapper>
            <div style={{ position: "relative" }}>
              {openPositions && (
                <OpenedDropdown style={{ margin: 0 }}>
                  {positions
                    .filter((item) => item.lexic_id !== null)
                    .map((item, index) => {
                      return (
                        <ParamCheckboxStyle key={item.id}>
                          <input
                            type="checkbox"
                            checked={selectedPosition
                              .split(",")
                              .filter(Boolean)
                              .includes(item.id.toString())}
                            value={item.id}
                            onChange={(e) => {
                              const selectedPosition = e.target.value;
                              setSelectedPosition((prevSelected) => {
                                // Clean up the previous selected state
                                const cleanedPrevSelected = prevSelected
                                  .split(",")
                                  .filter(Boolean);

                                let updatedSelected;

                                if (
                                  cleanedPrevSelected.includes(selectedPosition)
                                ) {
                                  // Remove the unselected position
                                  updatedSelected = cleanedPrevSelected.filter(
                                    (positionId) =>
                                      positionId !== selectedPosition
                                  );
                                } else {
                                  // Add the selected position
                                  updatedSelected = [
                                    ...cleanedPrevSelected,
                                    selectedPosition,
                                  ];
                                }

                                return updatedSelected.join(",");
                              });
                            }}
                          />
                          <label style={{ color: "#fff" }}>{item.name}</label>
                        </ParamCheckboxStyle>
                      );
                    })}
                </OpenedDropdown>
              )}
            </div>
            <br />
            <InputWrapper onClick={() => setOpenCountry(!openCountry)}>
              <input
                type="text"
                value={countryName}
                placeholder="Type Country name"
                // onChange={(e) => setCountryName(e.target.value)}
                onChange={(e) => {
                  setCountryName(e.target.value);
                  if (e.target.value) {
                    setOpenCountry(true);
                  } else {
                    setOpenCountry(false);
                  }
                }}
              />
              <img
                className="dropdownIcon"
                src={openCountry ? arrowIconUp : arrowIconDown}
                alt={openCountry ? "Collapse" : "Expand"}
              />
            </InputWrapper>
            <div style={{ position: "relative" }}>
              {openCountry && (
                <OpenedDropdown style={{ margin: 0 }}>
                  {country
                    .filter((item) => item.flag !== null)
                    .map((item, index) => {
                      return (
                        <ParamCheckboxStyle key={item.id}>
                          <input
                            type="checkbox"
                            value={item.id}
                            checked={selectedContry
                              .split(",")
                              .filter(Boolean)
                              .includes(item.id.toString())}
                            onChange={(e) => {
                              const selectedCountry = e.target.value;
                              setSelectedCountry((prevSelected = "") => {
                                let updatedSelected;

                                const selectedArray = prevSelected
                                  ? prevSelected.split(",")
                                  : [];

                                if (selectedArray.includes(selectedCountry)) {
                                  // Remove the unselected country
                                  updatedSelected = selectedArray.filter(
                                    (countryId) => countryId !== selectedCountry
                                  );
                                } else {
                                  // Add the selected country
                                  updatedSelected = [
                                    ...selectedArray,
                                    selectedCountry,
                                  ];
                                }

                                return updatedSelected.join(",");
                              });
                            }}
                          />
                          <label key={index} style={{ color: "#fff" }}>
                            {item.name}
                          </label>
                        </ParamCheckboxStyle>
                      );
                    })}
                </OpenedDropdown>
              )}
            </div>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={teamTypeName}
                placeholder="Select Team Type"
                readOnly
                onClick={() => setTeamType(!openTeamType)}
              />
              <img
                className="dropdownIcon"
                src={openTeamType ? arrowIconUp : arrowIconDown}
                alt={openTeamType ? "Collapse" : "Expand"}
                onClick={() => setOpenTeamType(!openTeamType)}
              />
              <div style={{ position: "relative" }}>
                {openTeamType && (
                  <OpenedDropdown>
                    {teamTypeOptions.map((item) => (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedTeamType === item.id}
                          onChange={(e) => {
                            const selectedId = e.target.value;
                            setSelectedTeamType(selectedId);
                            setTeamTypeName(
                              teamTypeOptions.find(
                                (option) => option.id === selectedId
                              )?.name || "Select Team Type"
                            );
                            setOpenTeamType(false);
                          }}
                        />
                        <label style={{ color: "#fff" }}>{item.name}</label>
                      </ParamCheckboxStyle>
                    ))}
                  </OpenedDropdown>
                )}
              </div>
            </InputWrapper>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={competitionName}
                placeholder="Type Competition name"
                // onChange={(e) => setCompetitionName(e.target.value)}
                onChange={(e) => {
                  setCompetitionName(e.target.value);
                  if (e.target.value) {
                    setOpenCompetition(true);
                  } else {
                    setOpenCompetition(false);
                  }
                }}
              />
              <img
                className="dropdownIcon"
                src={openCompetition ? arrowIconUp : arrowIconDown}
                alt={openCompetition ? "Collapse" : "Expand"}
                onClick={() => setOpenCompetition(!openCompetition)}
              />
            </InputWrapper>
            <div style={{ position: "relative" }}>
              {openCompetition && (
                <OpenedDropdown style={{ margin: 0 }}>
                  {competition.map((item, index) => {
                    return (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedCompetition
                            .split(",")
                            .filter(Boolean)
                            .includes(item.id.toString())}
                          onChange={(e) => {
                            const selectedCompetition = e.target.value;
                            setSelectedCompetition((prevSelected = "") => {
                              let updatedSelected;

                              const selectedArray = prevSelected
                                ? prevSelected.split(",")
                                : [];

                              if (selectedArray.includes(selectedCompetition)) {
                                // Remove the unselected country
                                updatedSelected = selectedArray.filter(
                                  (competitionId) =>
                                    competitionId !== selectedCompetition
                                );
                              } else {
                                // Add the selected country
                                updatedSelected = [
                                  ...selectedArray,
                                  selectedCompetition,
                                ];
                              }

                              return updatedSelected.join(",");
                            });
                          }}
                        />
                        <label key={index} style={{ color: "#fff" }}>
                          {item.name}
                        </label>
                      </ParamCheckboxStyle>
                    );
                  })}
                </OpenedDropdown>
              )}
            </div>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={genderName}
                placeholder="Select Gender"
                readOnly
                onClick={() => setOpenGender(!openGender)}
              />
              <img
                className="dropdownIcon"
                src={openCompetition ? arrowIconUp : arrowIconDown}
                alt={openCompetition ? "Collapse" : "Expand"}
                onClick={() => setOpenGender(!openGender)}
              />
              <div style={{ position: "relative" }}>
                {openGender && (
                  <OpenedDropdown>
                    {genderOptions.map((item) => (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedGender === item.id}
                          onChange={(e) => {
                            const selectedId = e.target.value;
                            setSelectedGender(selectedId);
                            setGenderName(
                              genderOptions.find(
                                (option) => option.id === selectedId
                              )?.name || "Select Gender"
                            );
                            setOpenGender(false); // Close dropdown after selection
                          }}
                        />
                        <label style={{ color: "#fff" }}>{item.name}</label>
                      </ParamCheckboxStyle>
                    ))}
                  </OpenedDropdown>
                )}
              </div>
            </InputWrapper>
            <br />
            <InputWrapper>
              <input
                type="text"
                value={legName}
                placeholder="Select Leg"
                readOnly
                onClick={() => setLeg(!openLeg)}
              />
              <img
                className="dropdownIcon"
                src={openLeg ? arrowIconUp : arrowIconDown}
                alt={openLeg ? "Collapse" : "Expand"}
                onClick={() => setOpenLeg(!openLeg)}
              />
              <div style={{ position: "relative" }}>
                {openLeg && (
                  <OpenedDropdown>
                    {legOptions.map((item) => (
                      <ParamCheckboxStyle key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedLeg === item.id}
                          onChange={(e) => {
                            const selectedId = e.target.value;
                            setSelectedLeg(selectedId);
                            setLegName(
                              legOptions.find(
                                (option) => option.id === selectedId
                              )?.name || "Select Leg"
                            );
                            setOpenLeg(false);
                          }}
                        />
                        <label style={{ color: "#fff" }}>{item.name}</label>
                      </ParamCheckboxStyle>
                    ))}
                  </OpenedDropdown>
                )}
              </div>
            </InputWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  type="number"
                  placeholder="Age From"
                  value={olderAge}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setOlderAge(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  type="number"
                  placeholder="Age To"
                  value={underAge}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setUnderAge(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  type="number"
                  placeholder="Height From"
                  value={heightTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setHeightTo(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  type="number"
                  placeholder="Height To"
                  value={heightFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setHeightFrom(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <h4 style={{ color: "#FFF" }}>Select Statistics (season avg.)</h4>
            <AgeWrapper>
              <InputWrapper>
                <input
                  min={0}
                  max={120}
                  type="number"
                  placeholder="Minutes From"
                  value={minPlayedTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setMinPlayedTo(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  min={0}
                  max={120}
                  type="number"
                  placeholder="Minutes To"
                  value={minPlayedFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setMinPlayerdFrom(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  type="number"
                  placeholder="Goals From"
                  value={goalsTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setGoalsTo(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  type="number"
                  placeholder="Goals To"
                  value={goalsFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setGoalsFrom(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  type="number"
                  placeholder="Assist From"
                  value={assistTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setAssistTo(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  type="number"
                  placeholder="Assist To"
                  value={assistFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setAssistFrom(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Pass accuracy (in %) From"
                  value={passAccuracyFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setPassAccuracyFrom(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Pass accuracy (in %) To"
                  value={passAccuracyTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setPassAccuracyTo(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Duels won (in %) From"
                  value={duelsWonFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setDuelsWonFrom(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Duels won (in %) To"
                  value={duelsWonTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setDuelsWonTo(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
            <AgeWrapper>
              <InputWrapper>
                <input
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Crosses From"
                  value={crossesFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setCrossesFrom(value);
                    }
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <input
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Crosses To"
                  value={crossesTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setCrossesTo(value);
                    }
                  }}
                />
              </InputWrapper>
            </AgeWrapper>
            <br />
          </FilterContentWrap>
          <Button
            disabled={loadingSearch}
            size={"full"}
            onClick={() => handleSearch()}
            title={loadingSearch ? "Loading..." : "search"}
          />
        </Filter>

        {playerSearchResults.length > 0 ? (
          <SmrtSearchSidebar
            table={playerSearchResults}
            page={page}
            totalPages={Math.ceil(totalPlayers / playersPerPage)}
            loadingSearch={loadingSearch}
            onPageChange={handlePageChange}
          />
        ) : (
          <EmptyState>
            <img src={emptySearch} alt={emptySearch} />
            <h3>No results for players</h3>
          </EmptyState>
        )}
      </MainWrapperFavorite>
    </MainWrapper>
  );
};

export default Index;
