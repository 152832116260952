import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// Style
import {
  MainWrapper,
  TopWrapper,
  MainWrapperFavorite,
} from "../../../style/DasboardMain";
import "react-tabs/style/react-tabs.css";

// Components
import Navigation from "../../../components/Navigation/Navigation";
import IntroText from "../../../components/introText/index";
import Search from "../../../components/inputs/Search";
import {
  TabsWrapper,
  TabListWrapper,
  TabTitle,
  TabContent,
  TopList,
} from "../../../style/Favorites";


// Language
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SmrtSearchTable from "../../../components/table/SmrtSearchTable";
import MatchTable from "../../../components/table/MatchTable";

import Overview from "../../../components/table/Overview";
import { breakpoints } from "../../../style/size";
import ButtonLink from "../../../components/link/ButtonLink";
import { LoadingWrapper } from "../../../style/Global";
import { Oval } from "react-loader-spinner";
import Breadcrumbs from "../../../components/breadcrumbs";

// Images
import starOn from "../../../images/star_on.svg";
import starOff from "../../../images/star_off.svg";
import { SearchWrapper } from "../../../components/inputs/Search";
import EventsMatches from "./EventsMatches";
import { $api, $apiV1, $apiV2 } from "../../../config/api";
import ShotsPlayer from "../player/ShotsPlayer";
import { teamEventsParams } from "../../../config/parametrs/events/teamEventsParams";
import { ParametersSelector } from "../../../components/ParametersSelector";
import { getTeamAttackVideos, getTeamDefenseVideos, getTeamParamMarkers } from "../../../helpers";
import ShotsTeam from "./ShotsTeam";
import CrossesTeam from "./CrossesTeam";
import { useNotification } from "../../../components/notification/useNotification";
import { NotificationList } from "../../../components/notification/NotificationList";

export const TeamCardWrap = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.lines};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.linesOpacity};
  margin-bottom: 60px;
`;

export const TeamContent = styled.div`
  padding: 20px;
  position: relative;
`;

export const TopContent = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(24, 25, 24, 1);

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 20px;
  }

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const BottomContent = styled.div``;

export const TeamLeftInfo = styled.div`
  position: relative;

  @media only screen and (${breakpoints.mobile}){
    display: flex;
    flex-direction:column-reverse;
  } 
  
  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 38px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  .react-tooltip {
    background: #cfdae6;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;

    h3 {
      font-family: "SatoshiBold";
      color: rgba(4, 8, 2, 1);
    }
  }
`;

export const FavoriteTeamWrap = styled.div`
  display: flex;
  flex-direciton: row;
  gap: 10px;

  img {
    width: 18px;
  }
`;

export const TeamRightInfo = styled.div`
  padding-right: 70px;
  @media only screen and (${breakpoints.mobile}) {
    padding: 0 0 0 20px;
  }

  ul {
    li {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }
`;

export const Stat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (${breakpoints.mobile}) {
    flex-wrap: wrap;
    gap: 10px;
    align-items: baseline;

    > div {
      width: 30%;
    }
  }
`;

export const TeamStatistics = styled.div`
  padding-top: 20px;

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }

  h4 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const SeeMoreButton = styled.div`
  // position: absolute;
  // right: 0px;
  // top: 0;
`;

export const AvatarWithCountryContainer = styled.div`
  position: relative;

  .flag {
    position: absolute;
    object-fit: cover;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`;

const Index = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const [openSeeMore, setOpenSeeMore] = useState(false);

  const { id } = useParams();

  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);

  const [isFavorite, setIsFavorite] = useState(false);

  const [teamMatches, setTeamMatches] = useState([]);
  const [overviwStat, setOverviewStas] = useState("");
  const [statMathc, setStatMatch] = useState();
  const [teamLeadersList, setTeamLeadersList] = useState();
  const [playersInfo, setPlayersInfo] = useState([]);

  const [paramsShotList, setParamsShotList] = useState("");
  const [paramsCrossesList, setParamsCrossesList] = useState("");

  const [selectedSeason, setSelectedSeason] = useState(
    sessionStorage.getItem("season")
      ? JSON.parse(sessionStorage.getItem("season"))
      : ""
  );

  const [attackVideosSet1, setAttackVideosSet1] = useState(null);
  const [attackVideosSet2, setAttackVideosSet2] = useState(null);

  const [defencekVideosSet1, setDefenceVideosSet1] = useState(null);
  const [defenceVideosSet2, setDefenceVideosSet2] = useState(null);

  const [loadingAttackVideosSet1, setLoadingAttackVideosSet1] = useState(true);
  const [loadingAttackVideosSet2, setLoadingAttackVideosSet2] = useState(true);

  const [loadingDefencekVideosSet1, setLoadingDefenceVideosSet1] =
    useState(true);
  const [loadingDefenceVideosSet2, setLoadingDefenceVideosSet2] =
    useState(true);

  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    const selectedSeasonId = event.target.value;
    sessionStorage.setItem("season", JSON.stringify(selectedSeasonId));
    setSelectedSeason(selectedSeasonId);
  };

  const getPlayers = async (id) => {
    setLoading(true);
    try {
      const response = await $apiV1.get(
        `/statistics/team/${id}?year_season_id=${selectedSeason}`
      );
      setTeam(response?.data);
      setIsFavorite(Boolean(response?.data?.in_favorites));
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTeamMatches = async (id, season) => {
    try {
      const response = await $apiV1.get(
        `/statistics/team_matches/${id}?year_season_id=${
          season || selectedSeason
        }`
      );
      setTeamMatches(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const teamLeaders = React.useCallback(
    async (id) => {
      setLoadingList(true);
      const season = selectedSeason;
      try {
        const response = await $apiV2.get(
          `/platform/team/${id}/overview?year_season_id=${season}`
        );
        setTeamLeadersList(response?.data);
      } catch (error) {
        console.error("Error fetching players:", error);
      } finally {
        setLoadingList(false);
      }
    },
    [selectedSeason]
  );

  const getMatches = async (id, season, params = []) => {
    try {
      let currentParams = params;
      if (sessionStorage.getItem("teamParameters") && params?.length === 0) {
        const params = JSON.parse(sessionStorage.getItem("teamParameters"));
        const checkedIds = params.flatMap((group) =>
          group[1].filter((item) => item.checked).map((item) => item.id)
        );
        currentParams = checkedIds;
      }
      const response = await $apiV2.get(
        `/platform/team/${id}/matches?year_season_id=${
          season || selectedSeason
        }&params_ids=${currentParams.join(",")}`
      );
      setStatMatch(response?.data);
      return response;
    } catch (error) {
      console.error("Error fetching players:", error);
      return Promise.reject(error);
    } finally {
    }
  };

  const getPlayersInfo = async (id) => {
    try {
      const response = await $apiV2.get(
        `/platform/team/${id}/players?year_season_id=${selectedSeason}`
      );
      setPlayersInfo(response?.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getShotParams = async () => {
    try {
      const response = await $apiV1.get(`/stats/parameters/team/?group=2`);
      setParamsShotList(response?.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const getCrossesParams = async () => {
    try {
      const response = await $apiV1.get(`/stats/parameters/team/?group=3`);
      setParamsCrossesList(response?.data.results);
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
    }
  };

  const [userDetail, setUserDetail] = useState("");

  const getUser = async () => {
    try {
      const response = await $apiV1.get(`/users/user_profile/`);
      setUserDetail(response?.data?.user);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getTeamParams = async () => {
    try {
      const response = await $apiV1.get(`/stats/parameters/team/`);
      console.log(response, "response");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAttacVideos = async (team_id, param_id, loadingFunc, setData) => {
    loadingFunc?.(true);
    try {
      let seasonYears = teamLeadersList?.available_seasons?.[0]?.id;
      if (selectedSeason) {
        const selectedSeasonYears = teamLeadersList?.available_seasons?.find(
          (item) => item.id === +selectedSeason
        );
        if (selectedSeasonYears) {
          seasonYears = selectedSeasonYears.id;
        }
      }
      const data = await getTeamAttackVideos(team_id, param_id, seasonYears);
      setData?.(data);
      return data;
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      loadingFunc?.(false);
    }
  };

  const getDefenseVideos = async (team_id, param_id, loadingFunc, setData) => {
    loadingFunc?.(true);
    try {
      let seasonYears = teamLeadersList?.available_seasons?.[0]?.id;
      if (selectedSeason) {
        const selectedSeasonYears = teamLeadersList?.available_seasons?.find(
          (item) => item.id === +selectedSeason
        );
        if (selectedSeasonYears) {
          seasonYears = selectedSeasonYears.id;
        }
      }
      const data = await getTeamDefenseVideos(team_id, param_id, seasonYears);
      setData?.(data);
      return data;
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      loadingFunc?.(false);
    }
  };
  const fetchVideos = async () => {
    if (
      id &&
      teamLeadersList?.in_attack &&
      teamLeadersList?.in_defence &&
      teamLeadersList?.available_seasons?.length > 0
    ) {
      // Fetch attack videos
      await getAttacVideos(
        id,
        1,
        setLoadingAttackVideosSet1,
        setAttackVideosSet1
      );
      await getAttacVideos(
        id,
        3,
        setLoadingAttackVideosSet2,
        setAttackVideosSet2
      );

      // Fetch defense videos
      await getAttacVideos(
        id,
        2,
        setLoadingDefenceVideosSet1,
        setDefenceVideosSet1
      );
      await getDefenseVideos(
        id,
        6,
        setLoadingDefenceVideosSet2,
        setDefenceVideosSet2
      );
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [id, teamLeadersList]);

  useEffect(() => {
    getPlayers(id);
    getMatches(id);
    teamLeaders(id);
    getPlayersInfo(id);
    // getParams();
    getShotParams();
    getCrossesParams();
    getUser();
    getTeamParams();
  }, [id, selectedSeason, teamLeaders]);

  const breadcrumbs = [
    {
      title: "Tournaments",
      link: "/tournaments",
    },
    {
      title: `${team?.team?.name}`,
    },
  ];

  const toggleFavorite = async () => {
    try {
      if (isFavorite) {
        await $apiV2.delete(`/users/favorites/team/${id}/`);
      } else {
        await $apiV2.post(`/users/favorites/team/`, { team: id });
      }
      setIsFavorite((prev) => !prev);
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  const { notifications, addNotification, removeNotification } =
    useNotification();

   const handleClickTeamParam = async (param_id, count) => {
     try {
       const controller = new AbortController();
       addNotification(
         `Wow that’s ${count} team clips you’ve selected. I’m fetching them for you`,
         "info",
         controller
       );
       document.body.classList.add("loading-cursor");
       const data = await getTeamParamMarkers(
         id,
         param_id,
         "in_attack",
         selectedSeason,
         controller
       );
       if (data?.results?.length > 0) {
         const markers = data.results.map((item) => item.id).join(",");
         window.open(`/tournaments/match/video?markers=${markers}`);
       } else {
         addNotification(
           `There are 0 clips in the response from the server.`,
           "warning"
         );
       }
     } catch (e) {
       console.log("error get param markers", e);
       if (e.type === "canceled") {
         addNotification(
           `The request to receive ${count} clips of the team has been canceled.`,
           "warning"
         );
         return;
       }
       addNotification(`Error in receiving team events. ${e.message}`, "error");
     } finally {
       document.body.classList.remove("loading-cursor");
     }
   };

  const isCanClickTeamStat = (item) => {
    return (
      item.count === 0 ||
      item.name.includes("%") ||
      item.name.includes("Mins Played")
    );
  };

   const [navigationOpen, setNavigationOpen] = useState(
     localStorage.getItem("navigationOpen")
   );

   useEffect(() => {
     const handleNavigationOpenChange = () => {
       setNavigationOpen(localStorage.getItem("navigationOpen"));
       console.log(localStorage.getItem("navigationOpen"), "ss");
     };

     window.addEventListener(
       "navigationOpenChange",
       handleNavigationOpenChange
     );

     // Cleanup event listener on component unmount
     return () => {
       window.removeEventListener(
         "navigationOpenChange",
         handleNavigationOpenChange
       );
     };
   }, []);

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <TopWrapper>
          <IntroText
            smallTitle={t("favorites.smallTitle")}
            title="Check Out the Team."
          />
          <Search />
        </TopWrapper>

        {loading ? (
          <LoadingWrapper>
            <Oval
              visible={true}
              height="80"
              width="80"
              secondaryColor={
                localStorage.getItem("theme") === "light"
                  ? "#4E5255"
                  : "#CFDAE6"
              }
              color={
                localStorage.getItem("theme") === "light"
                  ? "#2363F7"
                  : "#4fa94d"
              }
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </LoadingWrapper>
        ) : (
          <>
            <Breadcrumbs items={breadcrumbs} />

            <TeamCardWrap>
              <TeamContent>
                <TopContent>
                  <Info>
                    <AvatarWithCountryContainer>
                      <img src={team?.team?.logo} alt={team?.team?.logo} />{" "}
                      {team?.team?.country?.flag && (
                        <img
                          className="flag"
                          src={team.team.country.flag}
                          alt={team.team.country?.name}
                        />
                      )}
                    </AvatarWithCountryContainer>
                    <TeamLeftInfo>
                      {team?.team?.country?.short_name && (
                        <h5>{team.team.country.short_name}</h5>
                      )}
                      {isMobile && (
                        <SeeMoreButton
                        style={{marginTop: "10px"}}
                          onClick={() => setOpenSeeMore(!openSeeMore)}
                        >
                          <ButtonLink
                            title={`${openSeeMore ? "see less" : "see more"}`}
                            color={"gray"}
                            underline
                          />
                        </SeeMoreButton>
                      )}

                      <FavoriteTeamWrap>
                        <h2>
                          {team?.team?.name}
                          {/* <img
                            src={isFavorite ? starOn : starOff}
                            alt="Favorite star"
                            style={{
                              width: "18px",
                              height: "100%",
                              cursor: "pointer",
                            }}
                            onClick={toggleFavorite}
                          /> */}
                        </h2>
                      </FavoriteTeamWrap>
                      <h3>{team?.team?.team_status_display}</h3>
                    </TeamLeftInfo>
                  </Info>
                  <TeamRightInfo>
                    <ul>
                      {team?.matches_played?.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              navigate(
                                `/tournaments/competition/${item.season_id}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {item.title}
                          </li>
                        );
                      })}
                    </ul>
                  </TeamRightInfo>
                </TopContent>
                {openSeeMore && isMobile ? (
                  <BottomContent>
                    <TeamStatistics>
                      <Stat>
                        {team?.statistics &&
                          Object.keys(team?.statistics).map((key) => (
                            <div key={key}>
                              <h5>{key}</h5>
                              <h4>{team?.statistics[key]}</h4>
                            </div>
                          ))}
                      </Stat>
                    </TeamStatistics>
                  </BottomContent>
                ) : (
                  !isMobile && (
                    <BottomContent>
                      <TeamStatistics>
                        <Stat>
                          {/* {team?.statistics &&
                            Object.keys(team?.statistics).map((key) => (
                              <div key={key}>
                                <h5>{key}</h5>
                                <h4>{team?.statistics[key]}</h4>
                              </div>
                            ))} */}
                          {teamLeadersList?.main_stats.map((item, index) => {
                            const statsToDisplay = [
                              "Goals +",
                              "Red Cards",
                              "Yellow Cards",
                              "Passes",
                              "Passes +",
                              "Grave Mistakes",
                              "Passes -",
                              "Passes +, %",
                            ];

                            if (statsToDisplay.includes(item.name)) {
                              return (
                                <div key={index} className="statistics">
                                  <h5>{item.name}:</h5>
                                  <h4
                                    style={
                                      isCanClickTeamStat(item)
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() =>
                                      !isCanClickTeamStat(item) &&
                                      handleClickTeamParam(item.id, item.count)
                                    }
                                  >
                                    {item.count}
                                  </h4>
                                </div>
                              );
                            }
                            // return (
                            //   <div key={index} className="statistics">
                            //     <h5>{item.name}:</h5>
                            //     <h4
                            //     // style={
                            //     //   isCanClickTeamStat(item)
                            //     //     ? { cursor: "not-allowed" }
                            //     //     : { cursor: "pointer" }
                            //     // }
                            //     // onClick={() =>
                            //     //   !isCanClickTeamStat(item) &&
                            //     //   handleClickTeamParam(item.id, item.count)
                            //     // }
                            //     >
                            //       {item.count}
                            //     </h4>
                            //   </div>
                            // );
                          })}
                        </Stat>
                      </TeamStatistics>
                    </BottomContent>
                  )
                )}
              </TeamContent>
            </TeamCardWrap>

            {/* Tabs */}
            <TabsWrapper>
              <TopList>
                <TabListWrapper style={{ scrollbarWidth: "none" }}>
                  <TabTitle>overview</TabTitle>
                  <TabTitle>players</TabTitle>
                  <TabTitle>matches</TabTitle>
                  <TabTitle>events</TabTitle>
                  <TabTitle>shots</TabTitle>
                  <TabTitle>crosses</TabTitle>
                </TabListWrapper>
              </TopList>

              <TabContent>
                <Overview
                  table={team}
                  leaders={teamLeadersList}
                  loadingList={loadingList}
                  id={id}
                  handleSelectChange={handleSelectChange}
                  selectedSeason={selectedSeason}
                  loadingAttackVideosSet1={loadingAttackVideosSet1}
                  loadingAttackVideosSet2={loadingAttackVideosSet2}
                  loadingDefenceVideosSet2={loadingDefenceVideosSet2}
                  loadingDefencekVideosSet1={loadingDefencekVideosSet1}
                  attackVideosSet1={attackVideosSet1}
                  attackVideosSet2={attackVideosSet2}
                  defenceVideosSet2={defenceVideosSet2}
                  defencekVideosSet1={defencekVideosSet1}
                />
              </TabContent>
              <TabContent>
                <SmrtSearchTable
                  table={team?.players_list}
                  info={playersInfo}
                />
              </TabContent>
              <TabContent>
                <MatchTable
                  team={team?.team?.name}
                  table={teamMatches?.matches}
                  all={team}
                  statMathc={statMathc}
                  setStatMatch={setStatMatch}
                  onSeasonChange={(season, params) =>
                    getMatches(id, season, params)
                  }
                  season={selectedSeason}
                />
              </TabContent>
              <TabContent>
                <EventsMatches
                  team={team}
                  paramsList={teamEventsParams}
                  user={userDetail}
                />
              </TabContent>
              <TabContent>
                <ShotsTeam
                  players={team}
                  paramsList={teamEventsParams}
                  paramsShotList={paramsShotList}
                  user={userDetail}
                />
              </TabContent>
              <TabContent>
                <CrossesTeam
                  players={team}
                  paramsList={teamEventsParams}
                  paramsShotList={paramsCrossesList}
                />
              </TabContent>
            </TabsWrapper>
          </>
        )}
      </MainWrapperFavorite>

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />
    </MainWrapper>
  );
};

export default Index;
