import React, { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";

// Style
import {
  MainWrapper,
  MainWrapperFavorite,
  IntroWrap,
} from "../../style/DasboardMain";
import {
  PlayerCard,
  PlayerInfo,
  TopInfo,
  BottomInfo,
  LeftInfo,
  Profile,
  PlayerTitle,
  Title,
  RightInfo,
  PlayerClubInfo,
} from "../../style/CurrentClub";

// Components
import Navigation from "../../components/Navigation/Navigation";
import IntroText from "../../components/introText/index";
import Breadcrumbs from "../../components/breadcrumbs";
import ButtonLink from "../../components/link/ButtonLink";
import Button from "../../components/button/index";
import Modal from "../../components/modal/index";

// Images
import profile1 from "../../images/img_league.svg";
import englishFlag from "../../images/ic_eng_flag.svg";
import star from "../../images/ic_start.svg";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  TabContent,
  TabListWrapper,
  TabsWrapper,
  TabTitle,
  TopList,
} from "../../style/Favorites";

const CurrentClub = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const breadcrumbs = [
    {
      title: "Smrt Search",
      link: "/smrt-search",
    },
    {
      title: "Jerome Joshua Webster",
      link: "/smrt-search/1",
    },
    {
      title: "Current Club",
    },
  ];

  const modale = (
    <>
      <Modal isOpen={open} setIsOpen={setOpen} closeIc>
        <h1>Initiate Contact</h1>
        <p style={{ marginBottom: "20px" }}>
          Someone from PlayerHunter team will initate contact between you and
          player Name Surname. You will be notified…
        </p>
        <Button title="confirm" size={"full"} onClick={() => setOpen(!open)} />
      </Modal>
    </>
  );

   const [navigationOpen, setNavigationOpen] = useState(
     localStorage.getItem("navigationOpen")
   );

   useEffect(() => {
     const handleNavigationOpenChange = () => {
       setNavigationOpen(localStorage.getItem("navigationOpen"));
       console.log(localStorage.getItem("navigationOpen"), "ss");
     };

     window.addEventListener(
       "navigationOpenChange",
       handleNavigationOpenChange
     );

     // Cleanup event listener on component unmount
     return () => {
       window.removeEventListener(
         "navigationOpenChange",
         handleNavigationOpenChange
       );
     };
   }, []);

  return (
    <MainWrapper>
      <Navigation />
      <MainWrapperFavorite
        className={
          localStorage.getItem("navigationOpen") === "true"
            ? ""
            : "fullWidthPage"
        }
      >
        <IntroWrap>
          <IntroText
            smallTitle={t("smrtSearch.smallTitle")}
            title="Check Out the Player."
          />
        </IntroWrap>

        <Breadcrumbs items={breadcrumbs} />

        <PlayerCard>
          <PlayerInfo>
            <TopInfo>
              <LeftInfo>
                <Profile>
                  <img className="profile" src={profile1} alt={profile1} />
                  <img className="flag" src={englishFlag} alt={englishFlag} />
                </Profile>
                <PlayerTitle>
                  <span>United Kingdom</span>
                  <Title>
                    <h2>Jerome Joshua Webster</h2>
                    <img src={star} alt={star} />
                  </Title>
                  <h5>Defensive Midfielder </h5>
                </PlayerTitle>
              </LeftInfo>
              <RightInfo>
                <Button
                  title="see more"
                  color="secondary"
                  sizeResponse="full"
                />
                <Button
                  title="Initiate contact"
                  color="primary"
                  sizeResponse="full"
                  onClick={() => setOpen(!open)}
                />
              </RightInfo>
            </TopInfo>
            <BottomInfo>
              <PlayerClubInfo>
                <span>Current Club</span>
                <h5>Aston Villa</h5>
              </PlayerClubInfo>

              <PlayerClubInfo>
                <span>Past Club</span>
                <h5>Crvena Zvezda</h5>
              </PlayerClubInfo>

              <PlayerClubInfo>
                <span>Secondary Position</span>
                <h5>Goal Keeper</h5>
              </PlayerClubInfo>

              <PlayerClubInfo>
                <span>Foot</span>
                <h5>Right Foot</h5>
              </PlayerClubInfo>

              <PlayerClubInfo>
                <span>Age</span>
                <h5>12.10.1993 | 30y</h5>
              </PlayerClubInfo>

              <PlayerClubInfo>
                <span>Height</span>
                <h5>167cm</h5>
              </PlayerClubInfo>

              <PlayerClubInfo>
                <span>Weight</span>
                <h5>78kg</h5>
              </PlayerClubInfo>

              <PlayerClubInfo>
                <span>Compare Player</span>
                <ButtonLink
                  title="Compare"
                  color="primary"
                  underline
                  onClick={() => navigate("/smrt-search/compare")}
                />
              </PlayerClubInfo>
            </BottomInfo>
          </PlayerInfo>
        </PlayerCard>

        <TabsWrapper>
          <TopList>
            <TabListWrapper>
              <TabTitle>overview</TabTitle>
              <TabTitle>Matches</TabTitle>
              <TabTitle>Stats</TabTitle>
              <TabTitle>events</TabTitle>
              <TabTitle>Shots</TabTitle>
              <TabTitle>crosses</TabTitle>
            </TabListWrapper>
          </TopList>

          <TabContent>test</TabContent>
          <TabContent>Matches</TabContent>
          <TabContent>Matches</TabContent>
        </TabsWrapper>
      </MainWrapperFavorite>

      {modale}
    </MainWrapper>
  );
};

export default CurrentClub;
