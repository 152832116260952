import React from 'react'
import styled from 'styled-components'
import Notification from './Notification'

const NotificationListWrapper = styled.div`
    width: 300px;
    position: fixed;
    bottom: 10px;
    right: 60px;
`

export const NotificationList = ({ notifications, onClose }) => {

    return (
        <NotificationListWrapper>
            {
                notifications?.map((notification, index) => {
                    return (
                        <Notification
                            {...notification}
                            key={notification.id}
                            index={index}
                            onClose={onClose}
                        />
                    )
                }
                )}
        </NotificationListWrapper>
    )
}
