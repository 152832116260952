import React from "react";

// Style
import { CopyrightWrapper, Social, DesignedBy } from "../../style/LandingPage";

// Images
import instagram from "../../images/ic_instagram.svg";
import linkedIn from "../../images/ic_linkedin.svg";
import youtube from "../../images/ic_youtube.svg";
import facebook from "../../images/ic_facebook.svg";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Components
import ButtonLink from "../../components/link/ButtonLink";

// Language
import { useTranslation } from "react-i18next";

const Copyright = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  return (
    <CopyrightWrapper>
      {!isMobile ? (
        <>
          <h3>{t("copyright.rights")}</h3>
          <Social>
            <a href="https://www.instagram.com/smrtstats/" target="_blank">
              <img src={instagram} alt={instagram} />
            </a>
            <a
              href="https://www.linkedin.com/company/smrtstats/"
              target="_blank"
            >
              <img src={linkedIn} alt={linkedIn} />
            </a>
            <a href="https://www.youtube.com/@SmrtStats" target="_blank">
              <img src={youtube} alt={youtube} />
            </a>
            <a href="https://www.facebook.com/smrtstats" target="_blank">
              <img src={facebook} alt={facebook} />
            </a>
          </Social>
          <DesignedBy>
            {t("copyright.designedBy")}{" "}
            <ButtonLink
              title={"Qwertify"}
              href="https://qwertify.io/"
              color={"normal"}
              hoverColor={"primary"}
              underline
            >
              Qwertify
            </ButtonLink>
          </DesignedBy>
        </>
      ) : (
        <>
          <Social>
            <a href="/">
              <img src={instagram} alt={instagram} />
            </a>
            <a href="/">
              <img src={linkedIn} alt={linkedIn} />
            </a>
            <a href="/">
              <img src={youtube} alt={youtube} />
            </a>
            <a href="/">
              {" "}
              <img src={facebook} alt={facebook} />
            </a>
          </Social>
          <h3>{t("copyright.rights")}</h3>
          <DesignedBy>
            {t("copyright.designedBy")}{" "}
            <ButtonLink
              title={"Qwertify"}
              href="https://qwertify.io/"
              color={"normal"}
              hoverColor={"normal"}
              underline
            >
              Qwertify
            </ButtonLink>
          </DesignedBy>
        </>
      )}
    </CopyrightWrapper>
  );
};

export default Copyright;
