import React from "react";

// Components
import Navigation from "../../components/Navigation/LandingNavigation";

// Sections
import Baner from "./Baner";
import Clients from "./Clients";
import Services from "./Services";
import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import Copyright from "./Copyright";

const Main = () => {
  return (
    <div>
      <Navigation />
      <Baner />
      {/* <Clients /> */}
      <Services />
      <About />
      <Contact />
      <Footer />
      <Copyright />
    </div>
  );
};

export default Main;
