import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Style
import {
  AuthWrapper,
  FormWrapper,
  ContentLogin,
  ImageWrapper,
  Form,
  ConfirmWrapp,
  LinkWrap,
  EmptyColumn,
} from "../../style/Auth";

// Images
import logo from "../../images/ic_logo.svg";

// Components
import Button from "../../components/button/index";
import ButtonLink from "../../components/link/ButtonLink";
import Modal from "../../components/modal/index";

// Language
import { useTranslation, Trans } from "react-i18next";

const SuccessSend = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleLoginButton = () => {
    navigate("/login");
  };

  const modal = (
    <>
      <Modal isOpen={open} setIsOpen={setOpen} successIc>
        <h1>{t("forgotPassword.title")}</h1>
        <p style={{ fontSize: "18px", marginBottom: "40px" }}>
          Check out your email sales@smrtstats.com now and follow instructions
        </p>
        <Button
          title="okey"
          size="full"
          sizeOptionsResize
          onClick={() => setOpen(false)}
        />
      </Modal>
    </>
  );

  return (
    <AuthWrapper>
      <FormWrapper>
        <ContentLogin>
          <EmptyColumn></EmptyColumn>
          <Form>
            <img className="logo" src={logo} alt={logo} />

            <h1>{t("successSend.title")}</h1>
            <p style={{ marginBottom: 0 }}>
              <Trans
                i18nKey="successSend.desc"
                components={[<a href="/forgot-password" />]}
              />
            </p>

            <ConfirmWrapp>
              <Button
                title={t("successSend.button")}
                size={"full"}
                sizeResponse={"full"}
                onClick={() => handleLoginButton()}
              />
            </ConfirmWrapp>
          </Form>
          <LinkWrap>
            <h3>
              {t("successSend.noEmail")}
              <ButtonLink
                title={t("successSend.resend")}
                size="large"
                color={"primary"}
                underline
                onClick={() => setOpen(!open)}
              />{" "}
              {t("successSend.now")}
            </h3>
          </LinkWrap>
        </ContentLogin>
      </FormWrapper>
      <ImageWrapper></ImageWrapper>

      {modal}
    </AuthWrapper>
  );
};

export default SuccessSend;
