import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/user/userService";
import { useNavigate } from "react-router-dom";

// Style
import {
  AuthWrapper,
  FormWrapper,
  ContentLogin,
  ImageWrapper,
  Form,
  ConfirmWrapp,
  LinkWrap,
  EmptyColumn,
  FiedlWrapper,
} from "../../style/Auth";

// Images
import logo from "../../images/ic_logo.svg";

// Components
import TextInput from "../../components/inputs/TextInput";
import PasswordInput from "../../components/inputs/PasswordInput";
import Button from "../../components/button/index";
import ButtonLink from "../../components/link/ButtonLink";

// Language
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.user.errorLogin);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleSubmit = async () => {
    try {
      // Dispatch loginUser action and handle the response
      const result = await dispatch(loginUser({ email, password })).unwrap();
      if (result) {
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthWrapper>
      <FormWrapper>
        <ContentLogin>
          <EmptyColumn></EmptyColumn>
          <Form>
            <a href="/">
              <img className="logo" src={logo} alt="Logo" />
            </a>
            <h1>{t("login.title")}</h1>
            <p>{t("login.desc")}</p>

            <TextInput
              label={t("inputs.email")}
              type="email"
              value={email}
              placeholder={t("placeholders.email")}
              onChange={handleEmailChange}
              error={error}
              bottomText={error}
            />

            <FiedlWrapper>
              <PasswordInput
                label={t("inputs.password")}
                placeholder={t("placeholders.password")}
                value={password}
                onChange={handlePasswordChange}
              />
              {/* <ButtonLink
                title={t("login.forgotPassword")}
                size="small"
                color="gray"
                href="/forgot-password"
                underline
              /> */}
            </FiedlWrapper>
            {/* {error && <div style={{ color: "#fff" }}>{error}</div>} */}
            <ConfirmWrapp>
              <Button
                title={t("login.button")}
                size="full"
                sizeResponse="full"
                onClick={handleSubmit}
              />
            </ConfirmWrapp>
          </Form>
          <LinkWrap>
            <h3>
              {/* {t("login.noAcc")}{" "}
              <ButtonLink
                title={t("login.createAcc")}
                size="large"
                color="primary"
                underline
                href="/register"
              />{" "}
              {t("login.now")} */}
            </h3>
          </LinkWrap>
        </ContentLogin>
      </FormWrapper>
      <ImageWrapper></ImageWrapper>
    </AuthWrapper>
  );
};

export default Login;
