import React, { useState } from "react";

// Media Query
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../style/size";

// Style
import {
  TopTableNames,
  TableNameWrap,
  TableWrap,
  TableContent,
  Player,
  Position,
  Club,
  Passes,
  RightContent,
  SeeMoreWrap,
  Compare,
  ProfileImage,
  ImagesWrap,
  FilterButton,
} from "../../style/SmrtSearch";

// Images
import star from "../../images/ic_start.svg";
import compare from "../../images/ic_compare.svg";
import compareBlue from "../../images/compare_blue.svg";
import filer from "../../images/ic_filter.svg";
import emptyState from "../../images/contactLogo.svg";

// Componenst
import Empty from "../../components/empty/index";
import Filter from "../modal/Filter";
import Modal from "../../components/modal/index";
import Button from "../button";

// Language
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../notification/useNotification";
import { getPlayerParamMarkers } from "../../helpers";
import { NotificationList } from "../notification/NotificationList";

const SmrtSearchTable = ({ table, info }) => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const { t, i18n } = useTranslation();
  const [openFilter, setOpenFiler] = useState(false);

  const navigate = useNavigate();

  const { notifications, addNotification, removeNotification } = useNotification();

  const tableNames = [
    {
      id: 1,
      name: `${t("smrtSearch.player")}`,
    },
    {
      id: 2,
      name: `${t("smrtSearch.position")}`,
    },
    {
      id: 3,
      name: "YC",
    },
    {
      id: 4,
      name: "Passes",
    },
    {
      id: 5,
      name: "Goals +",
    },
    {
      id: 6,
      name: "RC",
    },
    {
      id: 7,
      name: "Assists",
    },
  ];

  console.log(info, "infoinfo");

  const isCanClickPlayerStat = (parameter) => {
    return parameter?.count === 0
      || parameter.parameter_name.includes('%')
      || parameter.parameter_name.includes('Mins Played')
  }

  const handleClickPlayerParam = async (param_id, player_id, count) => {
    try {
      const controller = new AbortController();
      addNotification(`Wow that’s ${count} player clips you’ve selected. I’m fetching them for you`, 'info', controller)
      document.body.classList.add('loading-cursor');
      const data = await getPlayerParamMarkers(player_id, param_id, '', controller);
      if (data?.results?.length > 0) {
        const markers = data.results.map(item => item.id).join(',');
        window.open(`/tournaments/match/video?markers=${markers}`)
      } else {
        addNotification(`There are 0 clips in the response from the server.`, 'warning')
      }
    } catch (e) {
      console.log('error get param markers', e);
      if (e.type === 'canceled') {
        addNotification(`The request to receive ${count} clips of the player has been canceled.`, 'warning')
        return
      }
      addNotification(`Error in receiving ${count} player clips.`, 'error')
    } finally {
      document.body.classList.remove('loading-cursor');
    }
  }

  const [successPopup, setSuccessPopup] = useState(false);

   const handleCompare = (item) => {
     // Retrieve the players array from local storage
     const storedPlayers = localStorage.getItem("players");
     let playersArray = [];

     if (storedPlayers) {
       // Parse the existing players array from local storage
       playersArray = JSON.parse(storedPlayers);
     }

     // Convert id to number (assuming id is initially a string)
     const playerId = parseInt(item.id, 10);

     // Check if the item ID is already in the array
     if (!playersArray.includes(playerId)) {
       // Add the new player ID to the array
       playersArray.push(playerId);
       // Store the updated array back in local storage
       localStorage.setItem("players", JSON.stringify(playersArray));
     }

      setSuccessPopup(!successPopup);
   };

    const modal = (
      <>
        {/* Success added to compare  */}
        <Modal isOpen={successPopup} setIsOpen={setSuccessPopup} closeIc>
          <h1>Successfully added to compare</h1>
          <br />
          <Button
            title="Go to compare"
            size={"full"}
            onClick={() => navigate(`/compare`)}
          />
        </Modal>
      </>
    );

      const formatNumber = (number) => new Intl.NumberFormat().format(number);

  return (
    <>
      {table?.length > 0 ? (
        <>
          {!isMobile && (
            <TopTableNames>
              {tableNames.map((item, index) => {
                return (
                  <TableNameWrap className="tableNameWrapTeamPlayer">
                    <h5 key={index}>{item.name}</h5>
                  </TableNameWrap>
                );
              })}
            </TopTableNames>
          )}
          <TableWrap>
            {table.map((item, index) => {
              return (
                <TableContent key={index} className="tableLabelWrapTeamPlayer">
                  <Player className="teamPlayerTab" style={{ color: "#fff" }}>
                    <ImagesWrap>
                      <img src={star} alt={star} />
                      <ProfileImage>
                        <img
                          style={{ width: "40px", height: "40px;" }}
                          src={item.photo ? item.photo : emptyState}
                          alt={item.photo}
                        />
                        <span>
                          <img src={item.flag} alt={item.flag} />
                        </span>
                      </ProfileImage>
                    </ImagesWrap>
                    <h3>{item.display_name}</h3>
                  </Player>

                  {!isMobile && (
                    <>
                      <Position>
                        <h3>{item.position ? item.position : "/"}</h3>
                      </Position>

                      {info
                        ?.filter((player) => player.id === item.id)
                        ?.map((player) => {
                          return player.params.map((param, index) => {
                            return (
                              <Passes
                                key={index}
                                style={
                                  isCanClickPlayerStat(param)
                                    ? { cursor: "not-allowed" }
                                    : { cursor: "pointer" }
                                }
                                onClick={() =>
                                  !isCanClickPlayerStat(param) &&
                                  handleClickPlayerParam(
                                    param.parameter_id,
                                    player.id,
                                    param.count
                                  )
                                }
                              >
                                <h3>
                                  {" "}
                                  {param.count
                                    ? formatNumber(param.count)
                                    : param.count}
                                </h3>
                              </Passes>
                            );
                          });
                        })}
                    </>
                  )}
                  <RightContent>
                    <SeeMoreWrap
                      onClick={() => navigate(`/tournaments/player/${item.id}`)}
                      onContextMenu={() =>
                        window.open(`/tournaments/player/${item.id}`, "_blank")
                      }
                    >
                      {t("smrtSearch.seeMore")}
                    </SeeMoreWrap>

                    {!isMobile && (
                      <Compare onClick={() => handleCompare(item)}>
                        {" "}
                        <img
                          src={
                            localStorage.getItem("theme") === "light"
                              ? compareBlue
                              : compare
                          }
                          alt={compare}
                        />
                      </Compare>
                    )}
                  </RightContent>
                </TableContent>
              );
            })}
          </TableWrap>
        </>
      ) : (
        <Empty
          title={t("smrtSearch.emptySearchTitle")}
          text={t("smrtSearch.emptySearchDesc")}
        />
      )}

      <Filter setOpenFiler={setOpenFiler} openFilter={openFilter} />

      {/* display notifications */}
      <NotificationList
        notifications={notifications}
        onClose={removeNotification}
      />

      {modal}
    </>
  );
};

export default SmrtSearchTable;
