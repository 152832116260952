import { useState } from 'react'

export const useNotification = () => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (
        message,
        type,
        controller = null, // new AbortController(),
        id = Date.now(),
    ) => {
        setNotifications((prevNotifications) => [...prevNotifications, { id, message, type, controller }]);
        setTimeout(() => {
            removeNotification(id);
        }, 60000);
    };

    const removeNotification = (id) => {
        setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
    };

    return {
        notifications,
        addNotification,
        removeNotification,
    }
}
