import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './icons';

const NotificationWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 80px;
  padding: 20px;
  background-color: ${(props) => props.theme.black};
  
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1.3fr 6fr 0.5fr;
  box-shadow: 0 15px 30px rgba(0,0,0,0.08);
  animation: fadeIn 0.5s;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.error {
    border-left: 8px solid ${(props) => props.theme.red};
  }

  &.success {
    border-left: 8px solid ${(props) => props.theme.green};
  }

  &.info {
    border-left: 8px solid ${(props) => props.theme.blueish4};
  }

  &.warning {
    border-left: 8px solid ${(props) => props.theme.yellow};
  }

  .container-1, .container-2 {
    align-self: center;
  }

  .container-2 p:first-child {
    font-size: 16px;
    font-family: "SatoshiBold";
    color:  ${(props) => props.theme.white};
    font-weight: 600;
    text-transform: capitalize;
  }


  .container-2 p:nth-child(2) {
    font-size: 12px;
    font-family: "SatoshiRegular";
   color:  ${(props) => props.theme.white};
    font-weight: 400;
  }

  .container-2 span {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 600;
    font-family: "SatoshiRegular";
    color:  ${(props) => props.theme.green};
    cursor: pointer;
  }

  button {
    align-self: flex-start;
    background-color: transparent;
    border: none;
    font-size: 25px;
    line-height: 0;
    cursor: pointer;
    color: ${props => props.theme.white};
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.fadeOut {
    animation: fadeOut 0.5s;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }
`;

const Notification = ({ message, controller, type, onClose, id }) => {
  const [isClosing, setIsClosing] = React.useState(false);
  const [showStopFetching, setShowStopFetching] = React.useState(type === 'info' && controller ? true : false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose?.(id);
    }, 500);
  };

  const handleAbortCurrentRequset = () => {
    controller?.abort();
    setShowStopFetching(false);
  }

  const icon = useMemo(() => {
    switch (type) {
      case 'success':
        return <SuccessIcon />
      case 'error':
        return <ErrorIcon />
      case 'info':
        return <InfoIcon />
      case 'warning':
        return <WarningIcon />
      default:
        return <InfoIcon />
    }
  }, [type])

  return (
    <NotificationWrapper className={`${type} ${isClosing ? 'fadeOut' : ''}`}>
      <div class="container-1">
        {icon}
      </div>
      <div class="container-2">
        <p>{type}</p>
        <p>{message}</p>
        {showStopFetching && <span onClick={handleAbortCurrentRequset}>Stop fetching</span>}
      </div>
      <button onClick={handleClose}>×</button>
    </NotificationWrapper>
  );
};

export default Notification;